import { Button, Card } from 'nhsuk-react-components';

import { Idp } from 'store/config.slice';
import { loginProps } from './login.types';

export const LoginView = ({ authUrls, sdcsClassicUrl }: loginProps) => (
    <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-full">
            <h1>Login</h1>
            <p>
                Please login using the identity Provider you have been advised to use. If you are
                unsure please contact the National Service Desk.
            </p>

            <Card.Group>
                <Card.GroupItem width="one-half">
                    <Card feature>
                        <Card.Content>
                            <Card.Heading className="nhsuk-heading-m">SDCS Classic</Card.Heading>
                            <Card.Description>
                                Login with SDCS Classic for collections such as GP Practice
                                eDeclaration
                            </Card.Description>
                            <Button href={sdcsClassicUrl}>Login</Button>
                        </Card.Content>
                    </Card>
                </Card.GroupItem>

                {authUrls.has(Idp.Cis2) && (
                    <Card.GroupItem width="one-half">
                        <Card feature>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">CIS2</Card.Heading>
                                <Card.Description>
                                    Login with CIS2 using a smart card for collections such as NWRS
                                </Card.Description>
                                <Button
                                    href={authUrls.get(Idp.Cis2)}
                                    className="nhsuk-u-margin-right-3"
                                >
                                    Login
                                </Button>
                            </Card.Content>
                        </Card>
                    </Card.GroupItem>
                )}

                {authUrls.has(Idp.SdcsCloud) && (
                    <Card.GroupItem width="one-half">
                        <Card feature>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">SDCS Cloud</Card.Heading>
                                <Card.Description>Login with SDCS Cloud</Card.Description>
                                <Button href={authUrls.get(Idp.SdcsCloud)}>Login</Button>
                            </Card.Content>
                        </Card>
                    </Card.GroupItem>
                )}

                {authUrls.has(Idp.Cognito) && (
                    <Card.GroupItem width="one-half">
                        <Card feature>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    Local Account
                                </Card.Heading>
                                <Card.Description>
                                    Local with a local account for dev / test
                                </Card.Description>
                                <Button id="local-login" href={authUrls.get(Idp.Cognito)}>
                                    Login
                                </Button>
                            </Card.Content>
                        </Card>
                    </Card.GroupItem>
                )}
            </Card.Group>
        </div>
    </div>
);
