import { Header } from 'nhsuk-react-components';
import { HeaderButton } from '@components/header';
import LogOut from '../logout/logout';
import React from 'react';

const AppHeader = ({ helpUrl }) => {
    return (
        <>
            <Header transactional>
                <Header.Container className="nhsuk-width-container-fluid">
                    <Header.Logo href="/" />
                    <Header.ServiceName href="/">Data Collections</Header.ServiceName>

                    <Header.Content>
                        {helpUrl && (
                            <HeaderButton href={helpUrl}>Help with this service</HeaderButton>
                        )}
                        <LogOut class="nhsuk-u-margin-left-3" />
                    </Header.Content>
                </Header.Container>
            </Header>
        </>
    );
};

export default AppHeader;
