import { ErrorMessage, Hint, Input, Label } from 'nhsuk-react-components';
import React from 'react';
import { nanoid } from 'nanoid';

class Time extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            isValid: undefined
        };

        this.value = this.getValue();
    }

    validate = () => {
        if (this.props.question.mandatory) {
            if (this.value.hour && this.value.minute && this.value.second) {
                this.validateTime();
            } else {
                this.setState({
                    error: 'Enter the ' + this.props.question.name,
                    isValid: false
                });
            }
        } else {
            if (this.value.hour || this.value.minute || this.value.second) {
                this.validateTime();
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        }
    };

    isInvalidNumber = value => isNaN(parseInt(value));

    isInvalidPeriod = value =>
        this.isInvalidNumber(value) ||
        value.includes('.') ||
        value.includes(' ') ||
        value.length > 2 ||
        value.includes('+');

    validateTime = () => {
        if (
            this.isInvalidPeriod(this.value.hour) ||
            this.isInvalidPeriod(this.value.minute) ||
            this.isInvalidPeriod(this.value.second) ||
            this.value.hour > 23 ||
            this.value.hour < 0 ||
            this.value.minute > 59 ||
            this.value.minute < 0 ||
            this.value.second > 59 ||
            this.value.second < 0
        ) {
            this.setState({
                error: 'Please enter a valid time',
                isValid: false
            });
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    getValue = () => {
        let returnValue = {
            hour: '',
            minute: '',
            second: ''
        };
        if (this.props.question.value && this.props.question.value.includes(':')) {
            const value = this.props.question.value.split(':');
            if (value.length === 3)
                returnValue = { hour: value[0], minute: value[1], second: value[2] };
        }
        return returnValue;
    };

    handleOnChange = async event => {
        const inputName = event.target.id.match('[hour|minute|second]+')[0];
        this.value[inputName] = event.target.value;
        await this.props.onChange({
            questionId: this.props.question.id,
            value:
                this.value.hour && this.value.minute && this.value.second
                    ? this.value.hour + ':' + this.value.minute + ':' + this.value.second
                    : undefined
        });

        this.validate();
    };

    render() {
        return (
            <div
                className={`nhsuk-form-group ${this.state.error ? ` nhsuk-form-group--error` : ``}`}
            >
                <Label>{this.props.question.name}</Label>
                <Hint>{this.props.question.hint_text}</Hint>
                <ErrorMessage>{this.state.error}</ErrorMessage>
                <div className="nhsuk-date-input">
                    <div className="nhsuk-date-input__item">
                        <Input
                            id={`hour-${nanoid()}`}
                            label="Hour"
                            type="number"
                            width="2"
                            min="0"
                            max="23"
                            error={this.state.error ? true : false}
                            value={this.value.hour}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnChange}
                            required={this.props.question.mandatory}
                        />
                    </div>
                    <div className="nhsuk-date-input__item">
                        <Input
                            id={`minute-${nanoid()}`}
                            label="Minute"
                            type="number"
                            width="2"
                            min="0"
                            max="59"
                            error={this.state.error ? true : false}
                            value={this.value.minute}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnChange}
                            required={this.props.question.mandatory}
                        />
                    </div>
                    <div className="nhsuk-date-input__item">
                        <Input
                            id={`second-${nanoid()}`}
                            label="Second"
                            type="number"
                            width="2"
                            min="0"
                            max="59"
                            error={this.state.error ? true : false}
                            value={this.value.second}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnChange}
                            required={this.props.question.mandatory}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Time;
