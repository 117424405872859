import { Button, Radios } from 'nhsuk-react-components';

import { FormSectionContainer } from '@form';
import React from 'react';
import { withRouter } from 'react-router-dom';

class MultipleSectionAddAnother extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: undefined,
            error: undefined
        };

        this.options = props.sectionSpec?.multiple_sections?.add_another;
    }

    handleChange = event => {
        this.setState({
            value: event.target.value,
            error: undefined
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        if (this.state.value === undefined) {
            const error =
                this.options?.error ??
                `Select yes if you would like to add another ${this.props.sectionSpec.name.toLowerCase()}`;

            this.setState({
                error: error
            });
        } else {
            let nextPath = this.props.nextPath;
            if (this.props.sectionSpec?.multiple_sections?.enable_skip_to_summary) {
                nextPath = `/${this.props.match.params.collection}/summary`;
            }

            if (this.state.value === 'yes') {
                let index = Number(this.props.match.params.index);
                index++;

                nextPath = `/${this.props.match.params.collection}/${this.props.match.params.section}/${index}`;

                this.props.onAddMultipleSection({
                    sectionId: this.props.sectionSpec.id,
                    index: index
                });
            }

            this.props.history.push(nextPath);
        }
    };

    render() {
        const yes = this.options?.radio_items?.yes ?? {
            display: `Yes, I want to add another ${this.props.sectionSpec.name.toLowerCase()}`
        };

        const no = this.options?.radio_items?.no ?? {
            display: `No, I do not want to add another ${this.props.sectionSpec.name.toLowerCase()}`
        };

        const label =
            this.options?.display ?? `Do you want to add another ${this.props.sectionSpec.name}?`;

        return (
            <FormSectionContainer sectionSpec={this.props.sectionSpec}>
                <form onSubmit={this.handleSubmit}>
                    <Radios
                        error={this.state.error}
                        hint={this.options?.hint_text}
                        label={label}
                        onChange={this.handleChange}
                    >
                        <Radios.Radio value="yes" hint={yes.hint_text}>
                            {yes.display}
                        </Radios.Radio>
                        <Radios.Radio value="no" hint={no.hint_text}>
                            {no.display}
                        </Radios.Radio>
                    </Radios>
                    <hr />
                    <Button type="submit">Save and Continue</Button>
                </form>
            </FormSectionContainer>
        );
    }
}

export default withRouter(MultipleSectionAddAnother);
