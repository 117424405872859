import { FormQuestionSection, MultipleSectionAdd, MultipleSectionAddAnother } from '@form';
import { getNextSection, getRequestedDataIndex } from '@util/multiple-sections';

import { PageNotFound } from '@pages';
import React from 'react';
import { createFutureRequiredQuestionsFromQuestionsAndProps } from '@util/warning-summary';
import { withRouter } from 'react-router-dom';

class FormSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFirstSection: undefined,
            multipleSectionCount: undefined,
            nextPath: undefined,
            questions: undefined,
            render: undefined,
            sectionSpec: undefined,
            showSaveAndExitButton: undefined,
            showSaveAndSkipButton: undefined,
            showSaveAndGoToSummaryButton: undefined,
            futureRequiredQuestions: undefined
        };
    }

    static getDerivedStateFromProps(props) {
        const update = props.collection.form ? true : false;

        if (update) {
            const sectionSpecs = props.collection.form.sections;
            const sectionId = props.match.params.section;
            const collectionUrlSlug = props.match.params.collection;
            const sectionSpecIndex = sectionSpecs.findIndex(x => x.id === sectionId);
            const sectionSpec = sectionSpecs.find(x => x.id === sectionId);
            if (!sectionSpec) {
                return {
                    render: 'not-found'
                };
            }

            const { nextPath, nextSectionId } = getNextSection(
                sectionSpec,
                sectionSpecs,
                props.data,
                collectionUrlSlug
            );

            // TODO: Review for a better way to do this
            const indexParam = props.match.params.index;
            const index = indexParam ? Number(indexParam) : undefined;
            const dataIndex = getRequestedDataIndex(props.data, sectionId, index);

            const dataSection = props.data[dataIndex] || { questions: [] };

            const questions = dataSection.questions;

            const futureRequiredQuestions = createFutureRequiredQuestionsFromQuestionsAndProps(
                questions,
                props
            );

            const enrichQuestions = questions => {
                questions.forEach(question => {
                    if (!question.ref) {
                        question.ref = React.createRef();
                    }
                });

                return questions;
            };

            let render;

            if (sectionSpec.allow_multiple && !index) {
                render = 'multiple-section-add';
            } else if (sectionSpec.allow_multiple && props.location.hash === '#add-another') {
                render = 'multiple-section-add-another';
            } else {
                render = 'form-questions';
            }

            return {
                isFirstLoad: dataSection.isFirstLoad,
                isFirstSection: sectionSpecIndex === 0 ? true : false,
                multipleSectionCount: props.data.filter(x => x.id === sectionSpec.id).length,
                nextPath: nextPath,
                nextSectionId,
                questions: enrichQuestions(questions),
                render: render,
                sectionSpec: sectionSpec,
                showSaveAndExitButton: props.collection.show_save_and_exit_button,
                showSaveAndSkipButton: props.collection.show_save_and_skip_to_summary_button,
                showSaveAndGoToSummaryButton: props.collection.show_save_and_go_to_summary_button,
                futureRequiredQuestions
            };
        }

        return null;
    }

    render() {
        switch (this.state.render) {
            case 'not-found':
                return <PageNotFound />;

            case 'form-questions':
                return (
                    <FormQuestionSection
                        collection={this.props.collection}
                        isFirstLoad={this.state.isFirstLoad}
                        isFirstSection={this.state.isFirstSection}
                        multipleSectionCount={this.state.multipleSectionCount}
                        nextPath={this.state.nextPath}
                        nextSectionId={this.state.nextSectionId}
                        onConditionalReveal={this.props.onConditionalReveal}
                        onCopyAnswer={this.props.onCopyAnswer}
                        onQuestionChange={this.props.onQuestionChange}
                        onRemoveSection={this.props.onRemoveSection}
                        onResetForm={this.props.onResetForm}
                        onSectionSubmit={this.props.onSectionSubmit}
                        onSkipSection={this.props.onSkipSection}
                        onSkipSections={this.props.onSkipSections}
                        onSubmissionIdChange={this.props.onSubmissionIdChange}
                        orgCode={this.props.orgCode}
                        questions={this.state.questions}
                        sectionSpec={this.state.sectionSpec}
                        showSaveAndExitButton={this.state.showSaveAndExitButton}
                        showSaveAndGoToSummaryButton={this.state.showSaveAndGoToSummaryButton}
                        showSaveAndSkipButton={this.state.showSaveAndSkipButton}
                        submissionId={this.props.submissionId}
                        handleDisable={this.props.handleDisable}
                        futureRequiredQuestions={this.state.futureRequiredQuestions}
                    />
                );

            case 'multiple-section-add':
                return (
                    <MultipleSectionAdd
                        collection={this.props.collection}
                        nextPath={this.state.nextPath}
                        onAddMultipleSection={this.props.onAddMultipleSection}
                        sectionSpec={this.state.sectionSpec}
                    />
                );

            case 'multiple-section-add-another':
                return (
                    <MultipleSectionAddAnother
                        nextPath={this.state.nextPath}
                        onAddMultipleSection={this.props.onAddMultipleSection}
                        sectionSpec={this.state.sectionSpec}
                    />
                );

            default:
                return null;
        }
    }
}

export default withRouter(FormSection);
