import { Button, Card } from 'nhsuk-react-components';
import { useHistory, useRouteMatch } from 'react-router-dom';

import React from 'react';
import { SubmissionIdOutput } from '@output';
import { setTitle } from '@util/browser';

const FormCompleted = props => {
    const match = useRouteMatch();
    const history = useHistory();

    setTitle(`Completed - ${props.collection.name}`);

    const handleClick = () => {
        const path = `/${match.params.collection}`;
        history.push(path);

        props.onResetForm();
    };

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>Form Submitted</h1>

                <Card feature>
                    <Card.Content>
                        <Card.Heading>Form Submitted</Card.Heading>
                        <Card.Description>
                            Your submission reference is{' '}
                            <strong>
                                <SubmissionIdOutput value={props.submissionId} />
                            </strong>
                            .
                        </Card.Description>
                    </Card.Content>
                </Card>

                <hr />
                <Button onClick={handleClick}>Back to Home</Button>
            </div>
        </div>
    );
};

export default FormCompleted;
