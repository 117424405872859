import { Textarea } from 'nhsuk-react-components';
import React from 'react';

class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validate = () => {
        const value = this.getValue();
        const questionName = this.props.question.name;
        const max = this.props.question.max_length;
        const min = this.props.question.min_length;

        if (this.props.question.mandatory) {
            if (!this.props.question.value) {
                this.setState({
                    error: 'Enter the ' + this.props.question.name,
                    isValid: false
                });
            } else if (min || max) {
                this.validateMinMax(min, max, value, questionName);
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        } else if (min || max) {
            this.validateMinMax(min, max, value, questionName);
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    validateMinMax = (min, max, value, questionName) => {
        let error,
            isValid = true;

        if (min || max) {
            if (max && min && (value.length > max || value.length < min) && value.length > 0) {
                error = `${questionName} must be between ${min} and ${max} characters`;
                isValid = false;
            } else if (min && value.length < min && value.length > 0) {
                error = `${questionName} must be minimum ${min} characters in length`;
                isValid = false;
            } else if (max && value.length > max && value.length > 0) {
                error = `${questionName} exceeds the max length of ${max} characters`;
                isValid = false;
            }
            this.setState({
                error,
                isValid
            });
        }
    };
    getValue = () => {
        return this.props.question.value ?? '';
    };
    handleChange = async event => {
        await this.props.onChange({
            questionId: this.props.question.id,
            value: event.target.value
        });

        this.validate();
    };

    render() {
        const value = this.props.question.value ?? '';
        const rows = this.props.question.rows ?? 5;
        return (
            <Textarea
                id={this.props.id}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={this.state.error}
                rows={rows}
                name={this.props.question.id}
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                required={this.props.question.mandatory}
            />
        );
    }
}

export default TextArea;
