import { FormQuestions } from '@form';
import { Radios } from 'nhsuk-react-components';
import React from 'react';

class Radio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validate = () => {
        if (this.props.question.mandatory) {
            if (!this.props.question.value) {
                this.setState({
                    error: 'Please make a selection',
                    isValid: false
                });
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    handleChange = async event => {
        await this.props.onChange({
            questionId: this.props.question.id,
            linked_questions: this.props.question.linked_questions
                ? this.props.question.linked_questions
                : null,
            option: this.props.question.options
                ? this.props.question.options.find(x => x.key === event.target.value)
                : null,
            value: event.target.value
        });

        this.validate();

        this.toggleConditionalRevealQuestions();
    };

    toggleConditionalRevealQuestions = () => {
        const value = this.props.question.value;

        // For the questions which have been revealed
        const option = this.props.question.options.find(x => x.key === value);
        if (option && option.questions) {
            this.props.onConditionalReveal({
                isRevealed: true,
                questionIds: option.questions.map(x => x.id)
            });
        }

        // And the ones which are hidden.  We don't know which option has just been
        // hidden, so we just hide them all.
        const questions = this.props.question.options
            .filter(x => x.key !== value)
            .filter(x => x.questions)
            .map(x => x.questions)
            .flat();

        if (questions.length > 0) {
            this.props.onConditionalReveal({
                isRevealed: false,
                questionIds: questions.map(x => x.id)
            });
        }
    };

    render() {
        const { value, mandatory } = this.props.question;

        // Some questions may have 'hidden' in the json file, so we need to check if the value of that hidden attribute is
        // true then the question should be hidden..
        let hasHidden = false;
        hasHidden = this.props.question.hidden ?? true;
        return !hasHidden || (hasHidden && !this.props.question.hidden) ? (
            <Radios
                inline={this.props.question.display === 'inline'}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                value={value}
                error={this.state.error}
            >
                {this.props.question.options.map((option, index) => (
                    <Radios.Radio
                        key={index}
                        value={option.key}
                        checked={option.key === value}
                        onChange={this.handleChange}
                        hint={option.hint_text}
                        required={mandatory}
                        conditional={
                            option.questions
                                ? option.questions.map((question, index) => (
                                      <FormQuestions
                                          question={this.props.questions.find(
                                              x => x.id === question.id
                                          )}
                                          key={index}
                                          onQuestionChange={this.props.onChange}
                                      />
                                  ))
                                : undefined
                        }
                    >
                        {option.value}
                    </Radios.Radio>
                ))}
            </Radios>
        ) : null;
    }
}

export default Radio;
