import { NameBoxProps, NameBoxState } from '@form-controls';
import React, { ChangeEvent, Component } from 'react';

import { Input } from 'nhsuk-react-components';
import { InputWidth } from 'nhsuk-react-components/dist/util/types/NHSUKTypes';

class NameBox extends Component<NameBoxProps, NameBoxState> {
    constructor(props: NameBoxProps) {
        super(props);
        this.state = {
            error: undefined,
            isReadOnly: false,
            isValid: false
        };
    }

    componentDidMount() {
        this.setState({ isReadOnly: this.props.question.isReadOnly });
    }

    componentDidUpdate(prevProps: NameBoxProps) {
        if (prevProps.question.isReadOnly !== this.props.question.isReadOnly) {
            this.setState({ isReadOnly: this.props.question.isReadOnly });
        }
    }

    validate = () => {
        const value = this.getValue();
        const questionName = this.props.question.name;

        if (this.props.question.mandatory && value.length === 0) {
            this.setState({
                error: `${questionName} is required`,
                isValid: false
            });
            return;
        }
        if (value.length === 0) {
            this.setState({
                error: undefined,
                isValid: true
            });
            return;
        }

        this.validateMinMaxName(value, questionName);
    };

    validateMinMaxName = (value: string, questionName: string) => {
        const { max_length, min_length } = this.props.question;
        const isMinNumber = typeof min_length === 'number';
        const isMaxNumber = typeof max_length === 'number';
        const validNamePattern = /^[A-Za-z]+([ '-]?[A-Za-z]+|[,][ ]?[A-Za-z]+)*$/.test(value);

        if (
            value.length > 0 &&
            isMaxNumber &&
            isMinNumber &&
            (value.length > max_length || value.length < min_length)
        ) {
            this.setState({
                error: `${questionName} must be between ${min_length} and ${max_length} characters`,
                isValid: false
            });
            return;
        }

        if (value.length > 0 && isMinNumber && value.length < min_length) {
            this.setState({
                error: `${questionName} must be greater than or equal to ${min_length} characters`,
                isValid: false
            });
            return;
        }

        if (value.length > 0 && isMaxNumber && value.length > max_length) {
            this.setState({
                error: `${questionName} must be less than or equal to ${max_length} characters`,
                isValid: false
            });
            return;
        }

        if (value.length > 0 && !validNamePattern) {
            this.setState({
                error: `The ${questionName} must start and end with letter. Numbers and special characters are not allowed apart from a single apostrophe ('), hyphen (-), comma (,) or space ( ) between two letters.`,
                isValid: false
            });
            return;
        }

        this.setState({
            error: undefined,
            isValid: true
        });
        return;
    };

    getValue = (): string => {
        return this.props.question.value ?? '';
    };

    getWidth = (): InputWidth => {
        const validWidthValues: InputWidth[] = [2, 3, 4, 5, 10, 20, 30];
        if (this.props.question.width && validWidthValues.includes(this.props.question.width)) {
            return this.props.question.width;
        }
        return 10;
    };

    handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.onChange({
            questionId: this.props.question.id,
            value: event.target.value
        });
        this.validate();
    };

    render() {
        const value = this.getValue();

        return (
            <Input
                id={this.props.id}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={this.state.error}
                width={this.getWidth()}
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                disabled={this.state.isReadOnly}
                required={this.props.question.mandatory}
            />
        );
    }
}

export default NameBox;
