import { Input } from 'nhsuk-react-components';
import React from 'react';

class NumberBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validate = () => {
        const value = this.getValue();
        const questionName = this.props.question.name;

        if (this.props.question.mandatory && value.length === 0) {
            this.setState({
                error: `Enter the ${questionName}`,
                isValid: false
            });
            return;
        }

        if (value.length === 0) {
            this.setState({
                error: undefined,
                isValid: true
            });
            return;
        }

        this.validateMinMaxStep(value, questionName);
    };

    validateMinMaxStep = (value, questionName) => {
        const { step, min, max } = this.props.question;
        const isMinNumber = typeof min === 'number';
        const isMaxNumber = typeof max === 'number';
        const isNumber = !isNaN(value);

        if (value.length > 0 && !isNumber) {
            this.setState({
                error: `${questionName} must be a number`,
                isValid: false
            });
            return;
        }

        if (value.length > 0 && isMaxNumber && isMinNumber && (value > max || value < min)) {
            this.setState({
                error: `${questionName} must be between ${min} and ${max}`,
                isValid: false
            });
            return;
        }

        if (value.length > 0 && isMinNumber && value < min) {
            this.setState({
                error: `${questionName} must be greater than or equal to ${min}`,
                isValid: false
            });
            return;
        }

        if (value.length > 0 && isMaxNumber && value > max) {
            this.setState({
                error: `${questionName} must be less than or equal to ${max}`,
                isValid: false
            });
            return;
        }

        if (value.length > 0 && step && value % step) {
            this.setState({
                error: `${questionName} must be in steps of ${step}`,
                isValid: false
            });
            return;
        }

        this.setState({
            error: undefined,
            isValid: true
        });
        return;
    };

    getValue = () => {
        return this.props.question.value ?? '';
    };

    handleChange = async event => {
        await this.props.onChange({
            questionId: this.props.question.id,
            value: event.target.value
        });

        this.validate();
    };

    ignoreWheel = event => {
        event.preventDefault();
    };

    handleFocus = () => {
        // done this way as React can't directly cancel a passive wheel event
        document.activeElement.addEventListener('wheel', this.ignoreWheel);
    };

    handleBlur = async event => {
        await this.handleChange(event);
        document.activeElement.removeEventListener('wheel', this.ignoreWheel);
    };

    render() {
        const value = this.getValue();

        // Some questions may have 'hidden' in the json file, so we need to check if the value of that hidden attribute is
        // true then the question should be hidden..
        let hasHidden = false;
        hasHidden = this.props.question.hidden ?? true;
        return !hasHidden || (hasHidden && !this.props.question.hidden) ? (
            <Input
                id={this.props.id}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={this.state.error}
                step={this.props.question.step}
                max={this.props.question.max}
                min={this.props.question.min}
                type="number"
                width="10"
                value={value}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                required={this.props.question.mandatory}
                disabled={this.props.question.isReadOnly}
            />
        ) : null;
    }
}

export default NumberBox;
