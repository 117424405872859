import { DeleteSubmission, ViewSubmission } from '@submission';
import { ErrorPage, PageNotFound, SessionExpired } from '@pages';
import { FormCompleted, FormSection, FormSummary, OrgNameBanner } from '@form';
import { Route, Switch, withRouter } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import {
    enrichDataSection,
    enrichExistingSubmission,
    getNewDataArray,
    showHideLinkedQuestions
} from '@services/form-data-section';
import {
    getFirstSectionPath,
    getRequestedDataIndex,
    getRequestedNewMultipleSectionDataIndex
} from '@util/multiple-sections';

import Api from '@services/api';
import { CollectionHome } from '@collection';
import React from 'react';
import TransferSubmission from './submission/transfer-submission/transfer-submission';
import axios from 'axios';
import { setTitle } from '@util/browser';
import { showHideSections } from 'services/form-data-section';
import update from 'immutability-helper';

class AppLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collection: undefined,
            data: undefined,
            render: 'loading',
            showOrgName: undefined,
            submissionId: undefined
        };

        this.api = new Api();

        this.handleApiErrors();
    }

    async componentDidMount() {
        if (isEmpty(this.props.organisation)) {
            const organisations = await this.api.getOrganisations();

            if (organisations.orgs.length === 1) {
                const { code, name } = organisations.orgs[0];

                this.props.setOrganisation({ org_code: code, org_name: name });
                this.props.history.push(`/${this.props.match.params.collection}`);
            } else {
                this.props.history.push('/');
                return;
            }
        }

        await this.loadCollectionData();
        this.resetDataArray();
    }

    handleApiErrors = () => {
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 404) {
                    return error;
                }
                if (error.response.status === 401) {
                    this.setState({
                        render: 'session-expired'
                    });
                } else {
                    this.setState({
                        render: 'error'
                    });
                }
            }
        );
    };

    loadCollectionData = async () => {
        try {
            const response = await this.api.getCollection();
            const collection = response.data;

            setTitle(collection.name);

            this.setState({
                collection: collection,
                render: 'collection',
                showOrgName: collection.show_org_name
            });
            this.props.setCollection(collection);
        } catch {
            this.setState({
                render: 'not-found'
            });
        }
    };

    resetDataArray = () => {
        if (this.state.collection) {
            this.setState({
                data: getNewDataArray(this.state.collection)
            });
        }
    };

    handleOrgCodeChange = ({ orgTypeCode, submissionId, orgCode, orgName }) => {
        this.setState({
            data: getNewDataArray(this.state.collection, orgTypeCode),
            submissionId
        });

        this.props.setOrganisation({ org_code: orgCode, org_name: orgName });
    };

    findQuestionValueInState = from => {
        const { section_id, question_id } = from;
        const section = this.state.data.find(s => {
            return s.id === section_id;
        });
        const question = section.questions.find(q => {
            return q.id === question_id;
        });
        return question.value;
    };

    handleCopyAnswer = event => {
        const { section_id, question_id } = event.to;
        this.handleQuestionChange({
            index: event.index,
            questionId: question_id,
            sectionId: section_id,
            value: this.findQuestionValueInState(event.from)
        });
    };

    handleQuestionChange = event => {
        const payload = {
            value: { $set: event.value }
        };

        this.updateQuestionState(payload, event.questionId, event.sectionId, event.index);

        // Check if the question has linked questions
        if (event.linked_questions) {
            showHideLinkedQuestions(
                event.questionId,
                event.linked_questions,
                event.questions,
                event.option
            );
        }
    };

    updateQuestionState = (questionUpdatePayload, questionId, sectionId, index) => {
        const data = this.state.data;
        const dataIndex = getRequestedDataIndex(data, sectionId, index);
        const dataSection = data[dataIndex];

        const sectionUpdatePayload = {};

        const questionSpecIndex = dataSection.questions.findIndex(x => x.id === questionId);
        if (questionSpecIndex >= 0) {
            sectionUpdatePayload.questions = {
                [questionSpecIndex]: questionUpdatePayload
            };
        }

        this.setState(prevState => {
            return update(prevState, {
                data: {
                    [dataIndex]: sectionUpdatePayload
                }
            });
        });
    };

    handleConditionalReveal = event => {
        for (const questionId of event.questionIds) {
            const payload = {
                showOnSummaryPage: { $set: event.isRevealed },
                includeInApiPayload: { $set: event.isRevealed }
            };

            this.updateQuestionState(payload, questionId, event.sectionId, event.index);
        }
    };

    handleDisable = event => {
        for (const questionId of event.questionIds) {
            const payload = {
                isReadOnly: { $set: event.isReadOnly }
            };
            this.updateQuestionState(payload, questionId, event.sectionId, event.index);
        }
    };

    handleSubmissionIdChange = submissionId => {
        this.setState({
            submissionId: submissionId
        });
    };

    handleResetForm = () => {
        this.resetDataArray();
    };

    handleAddMultipleSection = event => {
        const sectionSpecs = this.state.collection.form.sections;
        const sectionSpec = sectionSpecs.find(x => x.id === event.sectionId);
        const newSection = _.flow(_.cloneDeep, enrichDataSection)(sectionSpec);
        const data = this.state.data;

        const dataIndex = getRequestedNewMultipleSectionDataIndex(
            data,
            sectionSpecs,
            event.sectionId,
            event.index
        );

        this.setState(state => {
            const data = state.data;
            data.splice(dataIndex, 0, { ...newSection, multipleSectionId: event.index });

            return {
                data: data
            };
        });
    };

    handleSectionSubmit = event => {
        const dataIndex = getRequestedDataIndex(this.state.data, event.sectionId, event.index);

        this.setState(prevState => {
            const state = update(prevState, {
                data: {
                    [dataIndex]: {
                        isFirstLoad: {
                            $set: false
                        }
                    }
                }
            });
            return {
                data: showHideSections(state.collection.form.sections, state.data)
            };
        });
    };

    handleSkipSection = event => {
        const dataIndex = getRequestedDataIndex(this.state.data, event.sectionId, event.index);

        this.setState(state => {
            const data = state.data;
            data.splice(dataIndex, 1);

            return {
                data: data
            };
        });
    };

    handleSkipSections = event => {
        const { sectionId, nextSectionId } = event;

        this.setState(({ data }) => {
            const sectionSpecIndex = data.findIndex(({ id }) => id === sectionId);
            const nextSectionIndex =
                nextSectionId === 'summary'
                    ? data.length
                    : data.findIndex(({ id }) => id === nextSectionId);
            return {
                data: data.map((section, i) =>
                    i > sectionSpecIndex && i < nextSectionIndex
                        ? { ...section, show: false }
                        : i >= nextSectionIndex
                        ? { ...section, show: true }
                        : { ...section }
                )
            };
        });
    };

    handleRemoveSection = event => {
        const dataIndex = getRequestedDataIndex(this.state.data, event.sectionId, event.index);

        this.setState(state => {
            const data = state.data;
            data.splice(dataIndex, 1);

            return {
                data: data
            };
        });
    };

    handleLoadSubmission = async event => {
        this.setState({
            render: 'loading'
        });

        const { submissionId, orgCode } = event;
        const sectionSpecs = this.state.collection.form.sections;
        const submission = (await this.api.getSubmission(submissionId)) ?? { sections: [] };
        const { org_name } = submission;
        const data = enrichExistingSubmission(submission, sectionSpecs);

        this.props.setOrganisation({ org_name, org_code: orgCode });

        if (event.render === 'form') {
            const sectionSpec = this.state.collection.form.sections[0];
            const collectionUrlSlug = this.props.match.params.collection;
            const path = getFirstSectionPath(sectionSpec, data, collectionUrlSlug);
            this.props.history.push(path);
        }

        this.setState({
            data,
            render: event.render,
            submissionId
        });
    };

    handleViewSubmissionChangeClick = () => {
        this.setState({
            render: 'form'
        });
    };

    handleViewSubmissionBackClick = () => {
        this.setState({
            render: 'collection'
        });
    };

    render() {
        const orgCode = this.props?.organisation?.org_code;
        const orgName = this.props?.organisation?.org_name;

        switch (this.state.render) {
            case 'loading':
                return 'Loading...';

            case 'not-found':
                return <PageNotFound />;

            case 'session-expired':
                return <SessionExpired />;

            case 'error':
                return <ErrorPage />;

            default:
                return (
                    <>
                        <OrgNameBanner orgName={orgName} showOrgName={this.state.showOrgName} />
                        <Switch>
                            <Route path={`/:collection/submission/:submission_id/delete`}>
                                <DeleteSubmission
                                    collection={this.state.collection}
                                    submissionId={this.state.submissionId}
                                    backHomeHandler={this.handleViewSubmissionBackClick}
                                />
                            </Route>
                            <Route path={`/:collection/submission/:submission_id/transfer`}>
                                <TransferSubmission
                                    collection={this.state.collection}
                                    data={this.state.data}
                                    submissionId={this.state.submissionId}
                                    orgCode={orgCode}
                                />
                            </Route>
                            <Route path={`/:collection/submission/:submission_id`}>
                                <ViewSubmission
                                    backLink={this.handleViewSubmissionBackClick}
                                    collection={this.state.collection}
                                    data={this.state.data}
                                    onChangeClick={this.handleViewSubmissionChangeClick}
                                    orgCode={orgCode}
                                    submissionId={this.state.submissionId}
                                />
                            </Route>
                            <Route path={`/:collection/summary`}>
                                <FormSummary
                                    collection={this.state.collection}
                                    data={this.state.data}
                                    orgCode={orgCode}
                                    orgName={orgName}
                                    submissionId={this.state.submissionId}
                                />
                            </Route>
                            <Route path={`/:collection/completed`}>
                                <FormCompleted
                                    collection={this.state.collection}
                                    onResetForm={this.handleResetForm}
                                    submissionId={this.state.submissionId}
                                />
                            </Route>
                            <Route path={`/:collection/:section/:index?`}>
                                <FormSection
                                    collection={this.state.collection}
                                    data={this.state.data}
                                    onAddMultipleSection={this.handleAddMultipleSection}
                                    onConditionalReveal={this.handleConditionalReveal}
                                    onQuestionChange={this.handleQuestionChange}
                                    onCopyAnswer={this.handleCopyAnswer}
                                    onRemoveSection={this.handleRemoveSection}
                                    onResetForm={this.handleResetForm}
                                    onSectionSubmit={this.handleSectionSubmit}
                                    onSkipSection={this.handleSkipSection}
                                    onSkipSections={this.handleSkipSections}
                                    onSubmissionIdChange={this.handleSubmissionIdChange}
                                    orgCode={orgCode}
                                    orgName={orgName}
                                    submissionId={this.state.submissionId}
                                    handleDisable={this.handleDisable}
                                />
                            </Route>
                            <Route path={`/:collection`}>
                                <CollectionHome
                                    collection={this.state.collection}
                                    onLoadSubmission={this.handleLoadSubmission}
                                    onOrgCodeChange={this.handleOrgCodeChange}
                                    orgCode={orgCode}
                                />
                            </Route>
                            <Route component={PageNotFound} />
                        </Switch>
                    </>
                );
        }
    }
}

export default withRouter(AppLayout);
