import React, { useEffect } from 'react';
import { exchangeDalCodeForTokens, selectAuthState } from '../../../store/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useHistory, useParams } from 'react-router-dom';

import { ErrorPage } from '@pages';

interface LoginDalParams {
    code: string;
}

export const LoginDal = () => {
    const { code }: LoginDalParams = useParams();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { isLoaded, isLoggedIn, url_slug } = useAppSelector(selectAuthState);

    useEffect(() => {
        (async () => {
            await dispatch(exchangeDalCodeForTokens(code));
        })();
    }, [code, dispatch]);

    useEffect(() => {
        if (url_slug) {
            history.push(`/${url_slug}`);
        }
    }, [history, url_slug]);

    return (
        <>
            {!isLoaded && <>Loading...</>}
            {isLoaded && !isLoggedIn && <ErrorPage />}
        </>
    );
};
