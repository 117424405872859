import React, { createRef } from 'react';

import Api from '@services/api';
import ClearButton from './clear-button';
import { Input } from 'nhsuk-react-components';
import Loader from './loader';
import Results from './results';
import styles from './gmc.module.scss';

class Gmc extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();
        this.state = {
            data: [],
            dataLoading: false,
            error: undefined,
            focused: -1,
            isReadOnly: undefined,
            isValid: undefined,
            readOnly: false,
            showResultsDropDown: false
        };
    }

    componentDidMount() {
        window.addEventListener('click', this.handleWindowClicked);
        this.setIsReadOnly(this.props.question.isReadOnly);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.question.isReadOnly !== this.props.question.isReadOnly) {
            this.setIsReadOnly(this.props.question.isReadOnly);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleWindowClicked);
    }

    setData = value => {
        this.setState({
            data: value
        });
    };

    setIsReadOnly = value => {
        this.setState({
            isReadOnly: value
        });
    };

    setDataLoading = value => {
        this.setState({
            dataLoading: value
        });
    };

    setFocused = value => {
        this.setState({
            focused: value
        });
    };

    setShowResultsDropDown = value => {
        this.setState({
            showResultsDropDown: value
        });
    };

    setValidationError = (error, isValid) => {
        this.setState({
            error,
            isValid
        });
    };

    handleWindowClicked = e => {
        const { type } = e.target;
        if (type !== 'text') {
            this.setShowResultsDropDown(false);
        }
    };

    handleOnKeyDown = e => {
        const numFocusableItems = this.state.data.length - 1;

        switch (e.key) {
            case 'ArrowDown':
                if (this.state.focused < numFocusableItems) {
                    const newFocus = this.state.focused + 1;
                    this.setFocused(newFocus);
                    this.handleResultsScroll(newFocus);
                }
                break;
            case 'ArrowUp':
                if (this.state.focused > 0) {
                    const newFocus = this.state.focused - 1;
                    this.setFocused(newFocus);
                    this.handleResultsScroll(newFocus);
                }
                break;
            case 'Escape':
                this.inputRef.current.blur();
                this.setShowResultsDropDown(false);
                break;
            case 'Enter':
                e.preventDefault();
                this.handleUpdateResults();
                break;
            default:
                break;
        }
    };

    handleResultsScroll = focused => {
        const results = document.querySelector('#results');
        const item = document.querySelector('#results > li');
        if (results) {
            results.scrollTo({
                behavior: 'smooth',
                top: focused * item.getBoundingClientRect().height
            });
        }
    };

    handleOnInputChange = async e => {
        const { value } = e.target;

        if (value !== this.props.question.value) {
            this.showResultsDropDown(value.length);
            this.updateInputValue(this.props.question.id, value);
        }
    };

    handleOnInputFocus = async e => {
        this.showResultsDropDown(e.target.value.length);
    };

    validate = () => {
        const value = this.props.question.value ?? '';

        if (this.props.question.mandatory) {
            if (value.length === 0) {
                this.setValidationError('Enter the ' + this.props.question.name, false);
            } else {
                this.setValidationError(undefined, true);
            }
        } else {
            this.setValidationError(undefined, true);
        }
    };

    showResultsDropDown = count => {
        const minCount = 3;
        const isInputActive = this.inputRef.current === document.activeElement;
        const readOnly = this.state.readOnly;
        if (count >= minCount && isInputActive && !readOnly) {
            this.setShowResultsDropDown(true);
            this.search();
        } else {
            this.setShowResultsDropDown(false);
        }
    };

    updateInputValue = async (questionId, value) => {
        await this.props.onChange({
            questionId,
            value
        });

        this.validate();
    };

    async search() {
        this.setData([]);
        this.setState({ dataLoading: true });

        try {
            const api = new Api();
            const response = await api.getRefData(this.inputRef.current.value);

            if (response.data) {
                this.setData(response.data);
                this.setDataLoading(false);
                this.setFocused(-1);
            }
        } catch {
            this.setDataLoading(false);
            this.setFocused(-1);
        }
    }

    handleUpdateResults = async () => {
        this.populateLinkedFields();
        this.setData([]);
    };

    populateLinkedFields = () => {
        const refData = this.state.data[this.state.focused];
        const linkedQuestions = this.props.question.linked_questions;

        for (const questionId in linkedQuestions) {
            this.updateInputValue(linkedQuestions[questionId], refData?.[questionId]);
        }

        this.updateInputValue(this.props.question.id, refData?.gmc_ref_no);

        this.props.handleDisable({
            isReadOnly: refData?.gmc_ref_no ? true : false,
            questionIds: [this.props.question.id, ...Object.values(linkedQuestions)]
        });
    };

    clearInputValue = e => {
        e.preventDefault();
        this.populateLinkedFields();
    };

    render() {
        const value = this.props.question.value ?? '';

        return (
            <div className={`nhsuk-u-clear ${styles.SearchWithResults}`}>
                <Input
                    className={`${styles.SearchWithResultsInput}`}
                    disabled={this.state.isReadOnly}
                    error={this.state.error}
                    hint={this.props.question.hint_text}
                    id={this.props.id}
                    inputRef={this.inputRef}
                    label={this.props.question.name}
                    onChange={this.handleOnInputChange}
                    onFocus={this.handleOnInputFocus}
                    onKeyDown={this.handleOnKeyDown}
                    readOnly={this.state.readOnly}
                    value={value}
                    width="10"
                    required={this.props.question.mandatory}
                />
                <Results
                    data={this.state.data}
                    focused={this.state.focused}
                    handleClick={this.handleUpdateResults}
                    setFocused={this.setFocused}
                    visible={this.state.showResultsDropDown}
                />
                <ClearButton handleClick={this.clearInputValue} visible={this.state.isReadOnly} />
                <Loader loading={this.state.dataLoading} />
            </div>
        );
    }
}

export default Gmc;
