import { Redirect, Route, RouteProps } from 'react-router-dom';

import { selectAuthState } from 'store/auth.slice';
import { useAppSelector } from 'app/hooks';

export default function PrivateRoute({ children, ...props }: RouteProps) {
    const { isLoggedIn } = useAppSelector(selectAuthState);

    if (isLoggedIn) {
        return <Route {...props}>{children}</Route>;
    } else {
        return <Redirect to="/login" />;
    }
}
