import { Button, Fieldset, Label } from 'nhsuk-react-components';

import Api from '@services/api';
import React from 'react';
import { safeAccesFullName } from '@util/transfer-submission';
import { setTitle } from '@util/browser';
import { useHistory } from 'react-router-dom';
import { useTransfer } from 'context/TransferContext';

export const clickHandlers = {
    backHome: handler => {
        handler();
    }
};

export const apiHandlers = {
    transfer: async (submissionId, orgId, setTransferred) => {
        const api = new Api();
        const payload = {
            destination_org_code: orgId
        };
        try {
            const response = await api.transferSubmission(submissionId, payload);
            if (response.id) {
                setTransferred(true);
                return;
            }
        } catch (error) {
            throw Error(error);
        }
    }
};

export const userActionHandlers = {
    cancel: cancelHandler => cancelHandler()
};

const ConfirmTransferSubmission = props => {
    const history = useHistory();
    const destination = useTransfer();
    setTitle(`Confirm Transfer - ${props.collection.name}`);

    const cancelHandler = () => {
        history.push(`/${props.collection.url_slug}/submission/${props.submissionId}`);
    };

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>Confirm Transfer</h1>
                <Fieldset>
                    <Fieldset.Legend>
                        <div>
                            <Label>Name of Staff</Label>
                            <Label>{safeAccesFullName(props.data)}</Label>
                        </div>
                        <div className="nhsuk-u-margin-top-5">
                            <Label>Destination Organisation</Label>
                            <Label>{`${destination.Name} (${destination.OrgId})`}</Label>
                        </div>
                    </Fieldset.Legend>
                </Fieldset>
                <>
                    <span>
                        <Button
                            className="nhsuk-button nhsuk-button--primary"
                            aria-disabled="false"
                            type="submit"
                            onClick={event => {
                                apiHandlers.transfer(
                                    props.submissionId,
                                    destination.OrgId,
                                    props.setTransferred
                                );
                            }}
                        >
                            Transfer
                        </Button>
                    </span>
                    <span className="nhsuk-u-padding-left-3">
                        <Button
                            className="nhsuk-button nhsuk-button--secondary"
                            aria-disabled="false"
                            type="submit"
                            onClick={() => userActionHandlers.cancel(cancelHandler)}
                        >
                            Cancel
                        </Button>
                    </span>
                </>
            </div>
        </div>
    );
};

export default ConfirmTransferSubmission;
