import { FormQuestions, FormSectionContainer, WarningSummarySection } from '@form';
import { scrollToTopOfPage, setTitle } from '@util/browser';

import Api from '@services/api';
import { Button } from 'nhsuk-react-components';
import React from 'react';
import { createSectionWarningListFromProps } from '@util/warning-summary';
import { getSubmissionSectionPayload } from '@util/api-payloads';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

class FormQuestionSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            multipleSectionIndex: undefined,
            showRemoveButton: undefined,
            showSaveAndExitButton: undefined,
            showSaveAndSkipButton: undefined,
            showSaveAndGoToSummaryButton: undefined,
            showSkipButton: undefined,
            title: undefined,
            warningList: []
        };
    }

    static getDerivedStateFromProps(props) {
        const index = props.match.params.index;
        const warningList = createSectionWarningListFromProps(props);

        return {
            multipleSectionIndex: index ? Number(index) : undefined,
            showRemoveButton:
                props.sectionSpec.allow_multiple &&
                !props.isFirstLoad &&
                !(
                    props.sectionSpec?.multiple_sections?.must_have &&
                    props.multipleSectionCount === 1
                ),
            showSaveAndExitButton: props.showSaveAndExitButton,
            showSaveAndSkipButton: props.showSaveAndSkipButton,
            showSaveAndGoToSummaryButton:
                props.showSaveAndGoToSummaryButton && !props.sectionSpec.allow_multiple,
            showSkipButton:
                props.sectionSpec.allow_multiple &&
                props.isFirstLoad &&
                !props.sectionSpec?.multiple_sections?.hide_skip_button,
            title: `${props.sectionSpec.name} - ${props.collection.name}`,
            warningList
        };
    }

    validateSection = async () => {
        await this.validateQuestions();
    };

    submitData = async () => {
        this.props.onSkipSections({
            sectionId: this.props.sectionSpec.id,
            nextSectionId: this.props.nextSectionId
        });

        await this.sendData();

        this.props.onSectionSubmit({
            index: this.state.multipleSectionIndex,
            sectionId: this.props.sectionSpec.id
        });
    };

    isSectionValid = () => {
        const isSectionValid = !this.props.questions
            .filter(question => question.includeInApiPayload)
            .some(question => !question.ref.current.state.isValid);

        return isSectionValid;
    };

    handleSubmit = async event => {
        event.preventDefault();
        await event.currentTarget.blur();

        this.validateSection();

        if (this.isSectionValid()) {
            this.submitData();
            this.navigateToNextSection();
        }
    };

    handleSaveAndExit = async event => {
        event.preventDefault();
        await this.validateSection();

        if (this.isSectionValid()) {
            await this.submitData();
            this.props.onResetForm();

            this.navigateTo(`/${this.props.match.params.collection}`);
        }
    };

    handleSaveAndSkipButton = async event => {
        event.preventDefault();
        await event.currentTarget.blur();

        this.validateSection();

        if (this.isSectionValid()) {
            this.submitData();
            this.navigateTo(`/${this.props.match.params.collection}/summary`);
        }
    };

    handleChange = event => {
        this.props.onQuestionChange({
            index: this.state.multipleSectionIndex,
            sectionId: this.props.sectionSpec.id,
            questions: this.props.questions,
            ...event
        });

        const warningList = createSectionWarningListFromProps(this.props);
        this.setState({ warningList });
    };

    handleConditionalReveal = event => {
        this.props.onConditionalReveal({
            index: this.state.multipleSectionIndex,
            sectionId: this.props.sectionSpec.id,
            ...event
        });
    };

    handleDisable = event => {
        this.props.handleDisable({
            index: this.state.multipleSectionIndex,
            sectionId: this.props.sectionSpec.id,
            ...event
        });
    };

    sendData = async () => {
        const payload = {
            section: {
                id: this.props.sectionSpec.id,
                questions: getSubmissionSectionPayload(this.props.questions)
            }
        };

        if (this.props.submissionId) {
            await this.updateExistingSubmission(payload);
        } else {
            await this.createNewSubmission(payload);
        }
    };

    validateQuestions = () => {
        this.props.questions.forEach(question => {
            if (question.includeInApiPayload) {
                question.ref.current.validate();
            }
        });
    };

    createNewSubmission = async payload => {
        payload.org_code = this.props.orgCode;

        const api = new Api();
        const response = await api.postSubmission(payload);

        this.props.onSubmissionIdChange(response?.data?.id);
    };

    updateExistingSubmission = async payload => {
        const api = new Api();
        const id = this.props.submissionId;
        const multipleSectionIndex = this.state.multipleSectionIndex;

        if (this.props.sectionSpec.allow_multiple) {
            payload.section.index = multipleSectionIndex - 1;
        }
        await api.patchSubmission(payload, id);
    };

    navigateToNextSection = () => {
        if (this.props.sectionSpec.allow_multiple) {
            if (this.props.sectionSpec?.multiple_sections?.enable_skip_to_add_another) {
                this.navigateTo(`${this.props.location.pathname}#add-another`);
                return;
            }
            const multipleSectionIndex = this.state.multipleSectionIndex;
            const multipleSectionCount = this.props.multipleSectionCount;

            if (multipleSectionIndex < multipleSectionCount) {
                const collectionUrlSlug = this.props.match.params.collection;
                const sectionId = this.props.match.params.section;

                this.navigateTo(`/${collectionUrlSlug}/${sectionId}/${multipleSectionIndex + 1}`);
            } else {
                this.navigateTo(`${this.props.location.pathname}#add-another`);
            }
        } else {
            this.navigateTo(this.props.nextPath);
        }
    };

    navigateToAddOrAddAnother = () => {
        const multipleSectionCount = this.props.multipleSectionCount;
        const sectionPath = `/${this.props.match.params.collection}/${this.props.match.params.section}`;

        if (multipleSectionCount === 1) {
            this.navigateTo(sectionPath);
        } else {
            this.navigateTo(`${sectionPath}/${multipleSectionCount - 1}#add-another`);
        }
    };

    navigateTo = path => {
        this.props.history.push(path);
        scrollToTopOfPage();
    };

    handleSkip = () => {
        this.props.onSkipSection({
            index: this.state.multipleSectionIndex,
            sectionId: this.props.sectionSpec.id
        });

        this.navigateTo(this.props.nextPath);
    };

    handleRemove = () => {
        const api = new Api();

        const id = this.props.submissionId;
        const sectionId = this.props.sectionSpec.id;
        const multipleSectionIndex = this.state.multipleSectionIndex;

        this.props.onRemoveSection({
            index: multipleSectionIndex,
            sectionId: sectionId
        });

        api.deleteSubmissionSection(id, sectionId, multipleSectionIndex - 1);

        this.navigateToAddOrAddAnother();
    };

    handleCopyAnswer = data => {
        const index = this.state.multipleSectionIndex;
        if (index) {
            data.index = index;
        }
        return this.props.onCopyAnswer(data);
    };

    enrichQuestionPresentation(question) {
        if (question.newQuestion) {
            return (
                <div>
                    <span style={{ color: '#005EB8' }}>
                        <b>[New Question]</b>
                    </span>
                </div>
            );
        }

        if (question.revisedQuestion) {
            return (
                <div>
                    <span style={{ color: '#005EB8' }}>
                        <b>[Revised Question]</b>
                    </span>
                </div>
            );
        }
    }

    render() {
        setTitle(this.state.title);

        return (
            <FormSectionContainer sectionSpec={this.props.sectionSpec}>
                {!isEmpty(this.state.warningList) && (
                    <WarningSummarySection warningList={this.state.warningList} />
                )}
                <form key={this.props.sectionSpec.id}>
                    {this.props.questions
                        .filter(x => x.showOnFormSection)
                        .map((question, index) => (
                            <React.Fragment key={question.id}>
                                {this.enrichQuestionPresentation(question)}

                                <FormQuestions
                                    isFirstLoad={this.props.isFirstLoad}
                                    key={index}
                                    onConditionalReveal={this.handleConditionalReveal}
                                    onQuestionChange={this.handleChange}
                                    onCopyAnswer={this.handleCopyAnswer}
                                    orgCode={this.props.orgCode}
                                    question={question}
                                    questions={this.props.questions}
                                    handleDisable={this.handleDisable}
                                />
                            </React.Fragment>
                        ))}
                    <hr />

                    {this.state.showSaveAndGoToSummaryButton ? (
                        <Button
                            onClick={this.handleSaveAndSkipButton}
                            aria-label="Save and go to summary"
                        >
                            Save and go to summary
                        </Button>
                    ) : (
                        <Button onClick={this.handleSubmit} aria-label="Save and Continue">
                            Save and Continue
                        </Button>
                    )}

                    {this.state.showSaveAndSkipButton && (
                        <Button
                            secondary={true}
                            className="nhsuk-u-margin-left-2"
                            onClick={this.handleSaveAndSkipButton}
                        >
                            Save and Skip to Summary
                        </Button>
                    )}

                    {this.state.showSaveAndExitButton && (
                        <Button
                            secondary={true}
                            className="nhsuk-u-margin-left-2"
                            onClick={this.handleSaveAndExit}
                        >
                            Save and Exit
                        </Button>
                    )}

                    {this.state.showSkipButton && (
                        <Button
                            secondary={true}
                            className="nhsuk-u-margin-left-2"
                            onClick={this.handleSkip}
                        >
                            Skip
                        </Button>
                    )}

                    {this.state.showRemoveButton && (
                        <Button
                            secondary={true}
                            className="nhsuk-u-margin-left-2"
                            onClick={this.handleRemove}
                            type="button"
                        >
                            Remove
                        </Button>
                    )}
                </form>
            </FormSectionContainer>
        );
    }
}

export default withRouter(FormQuestionSection);
