import {
    BranchSite,
    Checkbox,
    CopyAnswer,
    Date,
    Gmc,
    Map,
    NHSNumber,
    NINumber,
    NameBox,
    NumberBox,
    OdsLookup,
    OpeningTimes,
    Radio,
    Select,
    TextArea,
    TextBlock,
    TextBox,
    Time,
    Total
} from '@form-controls';

import React from 'react';

class FormQuestions extends React.Component {
    render() {
        const question = this.props.question;

        const formProps = {
            key: question.id,
            onChange: this.props.onQuestionChange,
            question: question,
            ref: question.ref
        };

        return (
            {
                'branch-site': (
                    <BranchSite
                        {...formProps}
                        allowChanges={this.props.isFirstLoad}
                        orgCode={this.props.orgCode}
                    />
                ),
                'text-block': <TextBlock key={question.id} question={question} />,
                checkbox: (
                    <Checkbox
                        {...formProps}
                        onConditionalReveal={this.props.onConditionalReveal}
                        questions={this.props.questions}
                    />
                ),
                date: <Date {...formProps} />,
                time: <Time {...formProps} />,
                map: <Map {...formProps} orgCode={this.props.orgCode} />,
                'nhs-number': <NHSNumber {...formProps} />,
                number: <NumberBox {...formProps} />,
                'open-close-times': <OpeningTimes {...formProps} />,
                radio: (
                    <Radio
                        {...formProps}
                        onConditionalReveal={this.props.onConditionalReveal}
                        questions={this.props.questions}
                    />
                ),
                select: <Select {...formProps} />,
                text: <TextBox {...formProps} />,
                textarea: <TextArea {...formProps} />,
                'copy-answer': <CopyAnswer {...formProps} onCopyAnswer={this.props.onCopyAnswer} />,
                'ni-number': <NINumber {...formProps} />,
                'name-box': <NameBox {...formProps} />,
                gmc: <Gmc {...formProps} handleDisable={this.props.handleDisable} />,
                total: (
                    <Total
                        {...formProps}
                        questions={this.props.questions}
                        onCopyAnswer={this.props.onCopyAnswer}
                    />
                ),
                'ods-lookup': <OdsLookup {...formProps} />
            }[question.type] || <TextBox {...formProps} />
        );
    }
}

export default FormQuestions;
