import { Button, Fieldset, Radios } from 'nhsuk-react-components';
import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { setTitle } from '@util/browser';
import { useHistory } from 'react-router-dom';

const CollectionSwitcher = ({ organisation, collection, setCollection, permissions }) => {
    setTitle('Home');

    const orgCode = organisation?.org_code;
    const history = useHistory();
    setTitle('NHS England Forms Collections');
    const [options, setOptions] = useState([]);
    const [error, setError] = useState();
    const [isCollectionSelected, setCollectionSelected] = useState(false);
    const handleContinue = event => {
        const collectionUrlSlug = collection?.url_slug;
        event.preventDefault();
        event.target.blur();
        if (!isCollectionSelected) {
            setError('Choose a collection');
        } else {
            history.push(`/${collectionUrlSlug}`);
        }
    };

    useEffect(() => {
        async function buildCollectionData() {
            const handleUrlSlug = coll => {
                return event => {
                    setCollectionSelected(true);
                    setCollection(coll);
                    setError(false);
                };
            };

            const collectionHtmlOptions = permissions.map(({ collections, org_code }) => {
                if (org_code === orgCode) {
                    return collections.map(coll => {
                        const { url_slug, name, hint_text } = coll;
                        return (
                            <Radios.Radio
                                key={url_slug}
                                id={url_slug}
                                value={url_slug}
                                error={error ? error : undefined}
                                hint={hint_text}
                                onClick={handleUrlSlug(coll)}
                            >
                                {name}
                            </Radios.Radio>
                        );
                    });
                } else {
                    return [];
                }
            });
            setOptions(collectionHtmlOptions);
        }
        buildCollectionData();
    }, [error, setError, setCollection, orgCode, permissions]);

    return (
        !isEmpty(options) && (
            <>
                <Fieldset>
                    <Fieldset.Legend>Select Your Collection</Fieldset.Legend>
                    <Radios name="home_collection_name" id="home_collection_id" error={error}>
                        {options}
                    </Radios>
                </Fieldset>
                <Button className="nhsuk-u-margin-left-2" onClick={handleContinue}>
                    Continue
                </Button>
            </>
        )
    );
};

export default CollectionSwitcher;
