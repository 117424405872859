import Api from '@services/api';
import { Card } from 'nhsuk-react-components';
import React from 'react';
import { SubmissionWindowInfo } from '@submission';
import { isOpen } from '@util/submission-window';
import { withRouter } from 'react-router-dom';

class CollectionDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonText: undefined,
            isCompleted: false,
            isLoading: true,
            isSubmissionWindowOpen: isOpen(this.props.collection),
            organisationData: [],
            orgCode: undefined,
            submissionId: undefined,
            submissionStatus: undefined
        };

        this.orgsHtmlOptions = undefined;
    }

    async componentDidMount() {
        try {
            const api = new Api();
            const response = await api.getOrganisations();
            this.setState({
                organisationData: response
            });
            this.loadSubmission(response);
        } catch (e) {
            this.setState({
                organisationData: null
            });
        }
    }

    update = orgs => {
        let buttonText = 'Start';
        let isCompleted = false;
        let submissionId;
        let submissionStatus;

        const orgsData = orgs ? orgs.orgs : this.state.organisationData.orgs;
        const { name: orgName, org_type_code: orgTypeCode } = orgsData.find(
            x => x.code === this.props.orgCode
        );

        if (this.props.collection.org_submit_mode === 'single') {
            const submission = orgsData.find(x => x.code === this.props.orgCode).submissions[0];
            submissionId = submission?.id;
            submissionStatus = submission?.status;

            const allowEditCompletedSubmission =
                this.props.collection.submission_window && this.state.isSubmissionWindowOpen;

            switch (submission?.status) {
                case 'not-started':
                    buttonText = 'Start';
                    break;

                case 'in-progress':
                    buttonText = 'Continue';
                    break;

                case 'completed':
                    buttonText = allowEditCompletedSubmission ? 'Edit' : undefined;
                    isCompleted = allowEditCompletedSubmission ? false : true;
                    break;

                default:
                    throw new Error('Unknown submission status');
            }
        }
        const orgCode = this.props.orgCode;

        this.setState({
            buttonText,
            isCompleted,
            isLoading: false,
            orgCode,
            submissionId,
            submissionStatus
        });

        this.props.onOrgCodeChange({
            orgTypeCode,
            orgCode,
            orgName,
            submissionId
        });
    };

    navigateToFirstSectionOfForm = () => {
        const match = this.props.match;
        const section = this.props.collection.form.sections[0];
        const path = `/${match.params.collection}/${section.id}`;

        this.props.history.push(path);
    };

    navigateToViewSubmissionPage = submissionId => {
        const match = this.props.match;
        const path = `/${match.params.collection}/submission/${submissionId}`;

        this.props.history.push(path);
    };

    loadSubmissionData = submissionId => {
        this.props.onLoadSubmission({
            orgCode: this.props.orgCode,
            submissionId: submissionId
        });
    };

    loadSubmission = orgs => {
        if (this.props.collection.org_submit_mode === 'single-per-submitter') {
            const { id, status } = orgs.orgs[0].submissions[0];

            this.loadSubmissionData(id);

            if (status === 'not-started') {
                this.navigateToFirstSectionOfForm();
            } else {
                this.navigateToViewSubmissionPage(id);
            }

            return;
        }

        this.update(orgs);

        if (!this.state.isCompleted) {
            this.navigateToFirstSectionOfForm();

            if (this.state.submissionId) {
                this.props.onLoadSubmission({
                    orgCode: this.props.orgCode,
                    submissionId: this.state.submissionId
                });
            }
        }
    };

    render() {
        if (this.state.isLoading) {
            return 'Loading...';
        }

        return (
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-full">
                    <h1>{this.props.collection.name}</h1>

                    {this.props.collection.home_page === 'default' && (
                        <SubmissionWindowInfo collection={this.props.collection} />
                    )}

                    {this.state.isSubmissionWindowOpen && (
                        <>
                            {this.state.isCompleted && (
                                <Card feature>
                                    <Card.Content>
                                        <Card.Heading>Collection Completed</Card.Heading>
                                        <Card.Description>
                                            This submission has already been successfully completed.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(CollectionDefault);
