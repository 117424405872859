import { Input } from 'nhsuk-react-components';
import React from 'react';
import isNino from 'is-national-insurance-number';

class NINumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validate = () => {
        const value = this.getValue();
        const questionName = this.props.question.name;

        let error,
            isValid = true;

        if (this.props.question.mandatory || value.length) {
            if (!isNino(value)) {
                error = `Enter ${questionName} in the correct format For example, 'QQ123456C'`;
                isValid = false;

                if (value.length === 0) {
                    error = `The ${questionName} is required`;
                }

                if (value.length > 0 && value.length < 9) {
                    error = `The ${questionName} is too short`;
                }

                if (value.length > 9) {
                    error = `The ${questionName} is too long`;
                }
            }
            this.setState({
                error,
                isValid
            });
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    getValue = () => {
        return this.props.question.value ?? '';
    };

    handleChange = async event => {
        await this.props.onChange({
            questionId: this.props.question.id,
            value: event.target.value
        });

        this.validate();
    };

    render() {
        const value = this.getValue();
        return (
            <Input
                id={this.props.id}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={this.state.error}
                type="text"
                width="10"
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                required={this.props.question.mandatory}
            />
        );
    }
}

export default NINumber;
