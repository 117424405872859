import { ErrorMessage } from 'nhsuk-react-components';
import React from 'react';

class Total extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    componentDidMount() {
        if (this.props.question.from && this.props.question.to) {
            this.props.onCopyAnswer({
                from: this.props.question.from,
                to: this.props.question.to
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.questions !== this.props.questions) {
            if (this.props.question.calculate) {
                const calculatedValue = this.evalEquationString(this.props.question.calculate);
                this.props.onChange({
                    questionId: this.props.question.id,
                    value: calculatedValue.toString()
                });
            }
        }
    }

    validate = () => {
        if (this.props.question.validate) {
            const validated = this.evalEquationString(this.props.question.validate);
            if (validated) {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            } else if (!validated) {
                this.setState({
                    error: true,
                    isValid: false
                });
            }
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    getQuestionValueById = (questions, questionId) =>
        questions.find(q => q.id === questionId && q.includeInApiPayload === true) &&
        questions.find(q => q.id === questionId && q.includeInApiPayload === true).value;

    evalEquationString = equation => {
        const values = equation
            .split(' ')
            .map(item => {
                if (this.getQuestionValueById(this.props.questions, item))
                    return Number(this.getQuestionValueById(this.props.questions, item));
                else if (
                    item === '+' ||
                    item === '-' ||
                    item === '*' ||
                    item === '/' ||
                    item === '>=' ||
                    item === '<=' ||
                    item === '<' ||
                    item === '>'
                )
                    return item;
                else if (item === '=') return '===';
                else return '0';
            })
            .join(' ');
        // eslint-disable-next-line no-eval
        return eval(values);
    };

    getValue = () => {
        return this.props.question.value ?? '0';
    };

    render() {
        const value = this.getValue();
        let nhsFormGroup = 'nhsuk-form-group';
        if (this.state.error) {
            nhsFormGroup += ' nhsuk-form-group--error';
        }
        const errorText =
            this.state.error === true ? this.props.question.error_text : this.state.error;

        return (
            <div className={nhsFormGroup}>
                <h2 type="total">{`${this.props.question.name}: ${value}`}</h2>
                {this.props.question.hint_text && <p>{this.props.question.hint_text}</p>}
                {this.state.error && <ErrorMessage>{errorText}</ErrorMessage>}
            </div>
        );
    }
}

export default Total;
