import { ChevronLeftIcon } from 'nhsuk-react-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const BackLink = ({ to, text, backClick }) => {
    const handleChangeClick = event => {
        if (backClick) {
            backClick(event);
        }
    };
    return (
        <div className="nhsuk-back-link">
            <Link className="nhsuk-back-link__link" to={to} onClick={handleChangeClick}>
                <ChevronLeftIcon />
                {text}
            </Link>
        </div>
    );
};

BackLink.propTypes = {
    /**
     The url to navigate to
    */
    to: PropTypes.string.isRequired,
    /**
     The button label
    */
    text: PropTypes.string
};

BackLink.defaultProps = {
    to: '/',
    text: 'Back'
};

export default BackLink;
