import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const FormSectionContainer = props => {
    const headerHtml = props.sectionSpec.header
        ? Buffer.from(props.sectionSpec.header, 'base64').toString('utf8')
        : undefined;

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>{props.sectionSpec.name}</h1>
                {ReactHtmlParser(headerHtml)}
                {props.children}
            </div>
        </div>
    );
};

export default FormSectionContainer;
