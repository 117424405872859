import { getCurrentMoment, getMinMoment, getMoment } from '@util/date';

import { DateInput } from 'nhsuk-react-components';
import React from 'react';

class Date extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            isValid: undefined
        };

        this.value = this.getValue();
    }

    validate = () => {
        this.validateDateInput(this.value.day, 'day');
        this.validateDateInput(this.value.month, 'month');
        this.validateDateInput(this.value.year, 'year');
        if (this.props.question.mandatory) {
            if (this.value.day && this.value.month && this.value.year) {
                this.validateDate();
            } else {
                this.setState({
                    error: 'Enter the ' + this.props.question.name,
                    isValid: false
                });
            }
        } else {
            if (this.value.day || this.value.month || this.value.year) {
                this.validateDate();
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        }
    };

    isPastDate = () => {
        return getMoment(this.value).isBefore(getCurrentMoment().subtract(1, 'days'));
    };

    isFutureDate = () => {
        const dateParts = this.props.question.min_date.split('-');
        const minDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(this.value).isAfter(minDate);
    };

    isMaxDate = () => {
        const dateParts = this.props.question.max_date.split('-');
        const maxDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(this.value).isBefore(maxDate);
    };

    isInDateRange = () => {
        const maxDateParts = this.props.question.max_date.split('-');
        const maxDate = getMoment({
            day: maxDateParts[1],
            month: maxDateParts[2],
            year: maxDateParts[0]
        });

        const minDateParts = this.props.question.min_date.split('-');
        const minDate = getMoment({
            day: minDateParts[1],
            month: minDateParts[2],
            year: minDateParts[0]
        });

        return getMoment(this.value).isBetween(minDate, maxDate);
    };

    isMinimumAge = () => {
        var minBirthdate = getMinMoment(this.props.question.min_age);
        return (
            getMoment(this.value).isValid() &&
            getMoment(this.value).isBefore(getCurrentMoment()) &&
            getMoment(this.value).isSameOrBefore(minBirthdate)
        );
    };

    validateDate = () => {
        const moment = getMoment(this.value);

        if (moment.isValid() && this.value.year) {
            this.setState({
                error: undefined,
                isValid: true
            });
            const minDate = this.props.question.min_date;
            const maxDate = this.props.question.max_date;
            const minAge = this.props.question.min_age;
            const questionName = this.props.question.name;
            if (minDate && maxDate && !this.isInDateRange()) {
                this.setState({
                    error: `${questionName} must be between ${minDate} and ${maxDate}`,
                    isValid: false
                });
            } else if (minDate && !this.isFutureDate()) {
                this.setState({
                    error: 'Enter a later date for ' + questionName,
                    isValid: false
                });
            } else if (maxDate && !this.isMaxDate()) {
                this.setState({
                    error: 'Enter a earlier date for ' + questionName,
                    isValid: false
                });
            } else if (this.props.question.only_allow_past_date && !this.isPastDate()) {
                this.setState({
                    error: 'Future dates are not allowed for ' + questionName,
                    isValid: false
                });
            } else if (minAge && !this.isMinimumAge()) {
                this.setState({
                    error: 'The minimum required age for ' + questionName + ' is ' + minAge,
                    isValid: false
                });
            }
        } else {
            this.setState({
                error: 'Please enter a valid date',
                isValid: false
            });
        }
    };

    // Input validation for the day, month, and year fields that only allows for integer inputs
    // and the correct number of characters in each field
    validateDateInput = (dateInput, dateType) => {
        // If the field contains

        if (/^\d+$/.test(dateInput)) {
            // Field Length Validation
            switch (dateType) {
                // 2 Character input e.g. 10
                case 'day':
                    this.value.day = dateInput.slice(0, 2);
                    break;
                // 2 Character input e.g. 03
                case 'month':
                    this.value.month = dateInput.slice(0, 2);
                    break;
                // 4 Character input e.g 2000
                case 'year':
                    this.value.year = dateInput.slice(0, 4);
                    break;
                default:
                    break;
            }
        } else if (/[^0-9]/.test(dateInput)) {
            // Character Type Validation
            switch (dateType) {
                case 'day':
                    this.value.day = dateInput.replace(/[^0-9]/g, '');
                    this.validateDateInput(this.value.day, 'day');
                    break;
                case 'month':
                    this.value.month = dateInput.replace(/[^0-9]/g, '');
                    this.validateDateInput(this.value.month, 'month');
                    break;
                case 'year':
                    this.value.year = dateInput.replace(/[^0-9]/g, '');
                    this.validateDateInput(this.value.year, 'year');
                    break;
                default:
                    break;
            }
        }
    };

    getValue = () => {
        return (
            this.props.question.value ?? {
                day: '',
                month: '',
                year: ''
            }
        );
    };

    handleOnChange = async event => {
        const inputName = event.target.id.split('-').slice(-1);
        this.value[inputName] = event.target.value;
        await this.props.onChange({
            questionId: this.props.question.id,
            value: this.value
        });

        this.validate();
    };

    render() {
        return (
            <DateInput
                id={this.props.question.id}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={this.state.error}
            >
                <DateInput.Day
                    value={this.value.day}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnChange}
                    required={this.props.question.mandatory}
                />
                <DateInput.Month
                    value={this.value.month}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnChange}
                    required={this.props.question.mandatory}
                />
                <DateInput.Year
                    value={this.value.year}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnChange}
                    required={this.props.question.mandatory}
                />
            </DateInput>
        );
    }
}

export default Date;
