import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import './styles.scss';

import * as serviceWorker from './serviceWorker';

import {
    AboutEasterEggPage,
    AccessibilityPage,
    ErrorPage,
    Login,
    Oauth2CallbackPage,
    PageNotFound
} from '@pages';
import { AppBreadcrumb, AppFooter, AppHeader, ConfigProvider, LoginDal } from '@components/app';
import { AuthProvider, PrivateRoute } from 'auth';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { useState } from 'react';

import AppLayout from '@components/app-layout';
import { Home } from '@components/home';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { store } from 'app/store';

function App() {
    const [organisation, setOrganisation] = useState({});
    const [collection, setCollection] = useState({});
    const [home, setHome] = useState({});
    const { help_url } = collection;

    return (
        <div className="App">
            <AppHeader helpUrl={help_url} />
            <AppBreadcrumb
                collection={collection}
                setHome={setHome}
                setOrganisation={setOrganisation}
                setCollection={setCollection}
            />
            <div id="container">
                <section>
                    <div className="nhsuk-width-container-fluid">
                        <div className="nhsuk-main-wrapper" id="maincontent" role="main">
                            <ConfigProvider>
                                <AuthProvider>
                                    <Switch>
                                        <Route path="/m/:code" component={LoginDal} />
                                        <Route path="/login" component={Login} />
                                        <Route path="/.about" component={AboutEasterEggPage} />
                                        <Route
                                            path="/oauth2/callback"
                                            component={Oauth2CallbackPage}
                                        />
                                        <Route
                                            path="/accessibility"
                                            component={AccessibilityPage}
                                        />
                                        <Route path="/error" component={ErrorPage} />
                                        <PrivateRoute path="/:collection">
                                            <AppLayout
                                                setCollection={setCollection}
                                                organisation={organisation}
                                                setOrganisation={setOrganisation}
                                            />
                                        </PrivateRoute>
                                        <PrivateRoute path="/">
                                            <Home
                                                setCollection={setCollection}
                                                collection={collection}
                                                organisation={organisation}
                                                setOrganisation={setOrganisation}
                                                home={home}
                                            />
                                        </PrivateRoute>
                                        <Route component={PageNotFound} />
                                    </Switch>
                                </AuthProvider>
                            </ConfigProvider>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter helpUrl={help_url} />
        </div>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
