import { OdsLookupProps, OdsLookupState } from './ ods-lookup.interface';

import { Input } from 'nhsuk-react-components';
import { InputWidth } from 'nhsuk-react-components/dist/util/types/NHSUKTypes';
import React from 'react';
import { debounce } from 'lodash';
import { getOrganisation } from '../../../services/ods-api';

class OdsLookup extends React.Component<OdsLookupProps, OdsLookupState> {
    _isMounted = false;

    constructor(props: OdsLookupProps) {
        super(props);

        this.state = {
            error: undefined,
            orgcode: '',
            isValid: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const value = this.props.question.value || null;
        if (value) {
            this.setState({
                orgcode: value?.org_code
            });
        }
    }

    componentDidUpdate(prevProps: OdsLookupProps, prevState: OdsLookupState) {
        if (this.state.orgcode !== prevState.orgcode) {
            this.debouncedValidate();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validate = async () => {
        const { mandatory } = this.props.question;
        const { orgcode } = this.state;

        if (mandatory && (!orgcode || orgcode === '') && this._isMounted) {
            this.setState({
                error: 'Enter a valid ODS organisation code',
                isValid: false
            });
            return;
        }

        if (orgcode && this._isMounted) {
            const odsCode = await getOrganisation(orgcode);

            if (odsCode === undefined) {
                this.setState({
                    error: 'Enter a valid ODS organisation code',
                    isValid: false
                });
                return;
            }

            this.props.onChange({
                questionId: this.props.question.id,
                value: {
                    org_name: odsCode.Name,
                    org_code: orgcode
                }
            });
        }

        if (this._isMounted) {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    debouncedValidate = debounce(() => {
        this.validate();
    }, 500);

    getWidth = (): InputWidth => {
        const validWidthValues: InputWidth[] = [2, 3, 4, 5, 10, 20, 30];
        if (this.props.question.width && validWidthValues.includes(this.props.question.width)) {
            return this.props.question.width;
        }
        return 10;
    };

    handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (this._isMounted) {
            this.setState({
                orgcode: evt.target.value.toUpperCase()
            });
        }
    };

    render(): React.ReactNode {
        const { error, orgcode } = this.state;
        return (
            <Input
                id={this.props.id}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={error}
                value={orgcode}
                width={this.getWidth()}
                onChange={this.handleChange}
                onBlur={this.handleChange}
            />
        );
    }
}

export default OdsLookup;
