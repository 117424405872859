import { Button, Table } from 'nhsuk-react-components';
import { FilterToggleButton, Filters, PaginationContainer, TableHeader } from '@table';
import { Link, withRouter } from 'react-router-dom';

import Api from '@services/api';
import { CollectionSummaryTableCell } from '@components/collection';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SubmissionWindowInfo } from '@submission';
import { isOpen } from '@util/submission-window';
import { nanoid } from 'nanoid';
import { orderBy } from 'lodash';
import styles from './collection-summary.module.css';

class CollectionSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            collectionID: this.props.collection.name,
            isFilterOpen:
                sessionStorage.getItem('isFilterOpen') === 'true' &&
                sessionStorage.getItem('collectionID') === this.props.collection.name
                    ? true
                    : false,
            paging: {
                currentPage: 0,
                isPaginationRequired: false,
                numPages: 0,
                pageEndIndex: 0,
                pageStartIndex: 0,
                resultsPerPage: 50
            },
            submissionsDataCache: [],
            submissionsData: []
        };

        this.columnSpecs = [];
    }

    async componentDidMount() {
        const api = new Api();

        try {
            const submissionsResponse = await api.getSubmissions(this.props.orgCode);
            this.setState({
                submissionsDataCache: submissionsResponse.data.submissions,
                submissionsData:
                    this.state.submissionsData &&
                    sessionStorage.getItem('collectionID') === this.props.collection.name
                        ? this.state.submissionsData
                        : submissionsResponse.data.submissions,
                isLoading: false
            });
            this.columnSpecs = this.enrichColumnSpecs();
            this.setPagingState(1, submissionsResponse.data.submissions);
        } catch {
            this.setState({
                submissionsData: null
            });
        }
    }

    handleFilterToggle = e => {
        e.preventDefault();
        e.target.blur();
        this.setState(prevState => ({
            isFilterOpen: !prevState.isFilterOpen
        }));
        sessionStorage.setItem('isFilterOpen', JSON.stringify(!this.state.isFilterOpen));
    };

    setSubmissionData = submissionsData => {
        this.setState(
            {
                submissionsData: submissionsData
            },
            this.setPagingState(1, submissionsData)
        );
    };

    handleClick = () => {
        this.props.onCreateNewRecord();
    };

    getAllQuestionSpecs = sectionSpec => {
        const questionSpecs = sectionSpec.questions;
        const conditionalRevealQuestionSpecs = sectionSpec.questions
            .filter(x => x.options)
            .flatMap(x => x.options)
            .filter(x => x.questions)
            .flatMap(x => x.questions);

        return [...questionSpecs, ...conditionalRevealQuestionSpecs];
    };

    enrichColumnSpecs = () => {
        const collection = this.props.collection;
        const columnSpecs = collection.submission_summary;

        for (const columnSpec of columnSpecs) {
            if (columnSpec.section_id) {
                const sectionSpec = collection.form.sections.find(
                    x => x.id === columnSpec.section_id
                );

                if (columnSpec.question_id) {
                    const questionSpec = this.getAllQuestionSpecs(sectionSpec).find(
                        x => x.id === columnSpec.question_id
                    );

                    columnSpec.type = questionSpec.type;
                    columnSpec.options = questionSpec.options;
                }

                if (columnSpec.question_ids) {
                    columnSpec.questions = columnSpec.question_ids.map(questionId => {
                        const questionSpec = this.getAllQuestionSpecs(sectionSpec).find(
                            x => x.id === questionId
                        );

                        return {
                            id: questionId,
                            type: questionSpec.type,
                            options: questionSpec.options
                        };
                    });
                }
            } else {
                switch (columnSpec.id) {
                    case 'created_at':
                        columnSpec.type = 'date';
                        break;

                    case 'updated_at':
                        columnSpec.type = 'date';
                        break;

                    case 'status':
                        columnSpec.type = 'status';
                        break;

                    case 'id':
                        columnSpec.type = 'id';
                        break;

                    case 'org_name':
                        columnSpec.type = 'org_name';
                        break;

                    case 'actions':
                        columnSpec.type = 'actions';
                        break;

                    default:
                        columnSpec.type = undefined;
                }
            }
        }

        return columnSpecs;
    };

    onViewClick = submission => {
        this.props.onLoadSubmission({
            orgCode: submission.org_code,
            render: 'view-submission',
            submissionId: submission.id
        });
    };

    onEditClick = submission => {
        this.props.onLoadSubmission({
            orgCode: submission.org_code,
            render: 'form',
            submissionId: submission.id
        });
    };

    getFormUrl = () => {
        const match = this.props.match;
        const section = this.props.collection.form.sections[0];
        return `/${match.params.collection}/${section.id}`;
    };

    getViewSubmissionUrl = submission =>
        `/${this.props.match.params.collection}/submission/${submission.id}`;

    sortSubmissions = (sortField, sortDirection) => {
        this.setPagingState(1);

        const sortedSubmissionsData = orderBy(
            { ...this.state.submissionsData },
            sortField,
            sortDirection
        );

        this.setState({
            submissionsData: sortedSubmissionsData
        });
    };

    checkPaginationRequired = submissionsData => {
        return submissionsData.length > this.state.paging.resultsPerPage;
    };

    setPagingState = (setPageNum, unSetSubsData) => {
        const submissionsData = unSetSubsData ? unSetSubsData : this.state.submissionsData;
        const isPaginationRequired = this.checkPaginationRequired(submissionsData);
        let numPages, pageStartIndex, pageEndIndex;

        if (isPaginationRequired) {
            numPages = Math.ceil(submissionsData.length / this.state.paging.resultsPerPage);

            if (typeof setPageNum === 'string') {
                setPageNum = parseInt(setPageNum);
            }
            if (setPageNum > numPages) {
                setPageNum = numPages;
            }

            if (setPageNum && setPageNum > 1) {
                pageStartIndex = (setPageNum - 1) * this.state.paging.resultsPerPage;
                pageEndIndex = pageStartIndex + (this.state.paging.resultsPerPage - 1);
                if (pageEndIndex > submissionsData.length - 1) {
                    pageEndIndex = submissionsData.length - 1;
                }
            } else {
                setPageNum = 1;
                pageStartIndex = 0;
                pageEndIndex = this.state.paging.resultsPerPage - 1;
            }
        } else {
            numPages = 0;
            pageStartIndex = 0;
            pageEndIndex = submissionsData.length - 1;
        }

        this.setState({
            paging: {
                currentPage: setPageNum,
                isPaginationRequired: isPaginationRequired,
                numPages: numPages,
                pageEndIndex: pageEndIndex,
                pageStartIndex: pageStartIndex,
                resultsPerPage: this.state.paging.resultsPerPage,
                isFilterOpen: this.state.isFilterOpen
            }
        });
    };

    render() {
        if (this.state.isLoading) {
            return 'Loading...';
        }

        const enableViewSubmissionPage = this.props.collection.enable_view_submission_page;
        const isHideEdit = this.props.collection.hide_edit_start_column;
        const showViewColumn =
            enableViewSubmissionPage &&
            !this.props.collection.submission_summary.some(x => x.id === 'id');
        const isSubmissionWindowOpen = isOpen(this.props.collection);

        const tableBodyRows = (() => {
            const rows = [];
            const generateRow = function (submission) {
                return (
                    <Table.Row key={nanoid()}>
                        {this.columnSpecs.map(spec => {
                            const value = submission[spec.id]?.id
                                ? submission[spec.id]?.value
                                : submission[spec.id];

                            const columnSpec =
                                spec.questions?.find(x => x.id === submission[spec.id]?.id) ?? spec;

                            return (
                                <CollectionSummaryTableCell
                                    key={nanoid()}
                                    submission={submission}
                                    value={value}
                                    columnSpec={columnSpec}
                                    enableViewSubmissionPage={enableViewSubmissionPage}
                                    onViewClick={this.onViewClick}
                                />
                            );
                        })}

                        {showViewColumn && (
                            <Table.Cell>
                                {submission.status !== 'not-started' && (
                                    <Link
                                        to={this.getViewSubmissionUrl(submission.id)}
                                        onClick={() => this.onViewClick(submission)}
                                    >
                                        View
                                    </Link>
                                )}
                            </Table.Cell>
                        )}
                        {isSubmissionWindowOpen && (
                            <Table.Cell>
                                {!isHideEdit && (
                                    <Link
                                        to={this.getFormUrl()}
                                        onClick={() => this.onEditClick(submission)}
                                    >
                                        {submission.status === 'not-started' ? 'Start' : 'Edit'}
                                    </Link>
                                )}
                            </Table.Cell>
                        )}
                    </Table.Row>
                );
            }.bind(this);

            if (this.state.submissionsData.length) {
                for (
                    let i = this.state.paging.pageStartIndex;
                    i <= this.state.paging.pageEndIndex;
                    i++
                ) {
                    rows.push(generateRow(this.state.submissionsData[i], i));
                }

                return rows;
            }
        })();

        const headerHtml = this.props.collection.submission_summary_header
            ? Buffer.from(this.props.collection.submission_summary_header, 'base64').toString(
                  'utf8'
              )
            : undefined;

        return (
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-full">
                    <h1>{this.props.collection.name}</h1>

                    <SubmissionWindowInfo collection={this.props.collection} />
                    {ReactHtmlParser(headerHtml)}
                    {isSubmissionWindowOpen ? (
                        <Filters
                            className={`${styles.collectionSummaryFilter} nhsuk-u-margin-right-4`}
                            isFilterOpen={this.state.isFilterOpen}
                            setSubmissionData={this.setSubmissionData}
                            submissionSummary={this.props.collection.submission_summary}
                            submissionsDataCache={this.state.submissionsDataCache}
                            collectionID={this.props.collection.name}
                        />
                    ) : (
                        <></>
                    )}
                    <div className={styles.collectionSummaryTable}>
                        {isSubmissionWindowOpen && (
                            <Button onClick={this.handleClick}>Create New Record</Button>
                        )}
                        {isSubmissionWindowOpen && this.state.submissionsData.length > 1 ? (
                            <span className="nhsuk-u-margin-left-4">
                                <FilterToggleButton
                                    handleFilterToggle={this.handleFilterToggle}
                                    isFilterOpen={this.state.isFilterOpen}
                                />
                            </span>
                        ) : (
                            <></>
                        )}
                        {isSubmissionWindowOpen && this.state.submissionsData.length ? (
                            <>
                                <Table>
                                    <TableHeader
                                        columnSpecs={this.columnSpecs}
                                        sortSubmissions={this.sortSubmissions}
                                        isSubmissionWindowOpen={isSubmissionWindowOpen}
                                        showViewColumn={showViewColumn}
                                        actionColumnText={'Action'}
                                        isHideEdit={isHideEdit}
                                    />
                                    <Table.Body>{tableBodyRows}</Table.Body>
                                </Table>
                                <PaginationContainer
                                    handlePageClick={this.setPagingState}
                                    paging={this.state.paging}
                                    results={this.state.submissionsData}
                                />
                            </>
                        ) : (
                            <div>
                                <p>No records found</p>
                                <hr />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(CollectionSummary);
