/* eslint-disable no-dupe-else-if */
// TODO Remove eslint ignore run

import { Input, Select, Table } from 'nhsuk-react-components';
import React from 'react';

class OpeningTimes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validateAllOpenCloseTimes = () => {
        let isValid = true;
        const value = this.getValue();

        for (const key in value) {
            const day = value[key];
            if (
                day.am_open === '' &&
                day.am_close === '' &&
                day.pm_open === '' &&
                day.pm_close === ''
            ) {
                isValid = false;
            } else if (
                day.am_open !== '' &&
                day.am_close === '' &&
                day.pm_open === '' &&
                day.pm_close === ''
            ) {
                isValid = false;
            } else if (
                day.am_open !== '' &&
                day.am_close === '' &&
                day.pm_open !== '' &&
                day.pm_close === ''
            ) {
                isValid = false;
            } else if (
                day.am_open === '' &&
                day.am_close !== '' &&
                day.pm_open === '' &&
                day.pm_close !== ''
            ) {
                isValid = false;
            } else if (
                day.am_open !== '' &&
                day.am_close === '' &&
                day.pm_open !== '' &&
                day.pm_close !== ''
            ) {
                isValid = false;
            } else if (
                day.am_open === '' &&
                day.am_close !== '' &&
                day.pm_open !== '' &&
                day.pm_close !== ''
            ) {
                isValid = false;
            } else if (
                day.am_open !== '' &&
                day.am_close !== '' &&
                day.pm_open === '' &&
                day.pm_close !== ''
            ) {
                isValid = false;
            } else if (
                day.am_open !== '' &&
                day.am_close !== '' &&
                day.pm_open !== '' &&
                day.pm_close === ''
            ) {
                isValid = false;
            } else if (
                day.am_open !== '' &&
                day.am_close !== '' &&
                day.pm_open === '' &&
                day.pm_close !== ''
            ) {
                isValid = false;
            } else if (
                day.am_open === '' &&
                day.am_close === '' &&
                day.pm_open === '' &&
                day.pm_close !== ''
            ) {
                isValid = false;
            } else if (
                day.am_open === '' &&
                day.am_close !== '' &&
                day.pm_open !== '' &&
                day.pm_close === ''
            ) {
                isValid = false;
            } else if (
                day.am_open === '' &&
                day.am_close === '' &&
                day.pm_open !== '' &&
                day.pm_close === ''
            ) {
                isValid = false;
            } else if (
                day.am_open === '' &&
                day.am_close !== '' &&
                day.pm_open === '' &&
                day.pm_close === ''
            ) {
                isValid = false;
            }
        }
        return isValid;
    };

    validateOpenTimesByRow = () => {
        let isValid = true;
        const value = this.getValue();

        for (const key in value) {
            const row = value[key];

            if (
                row.am_open !== '' ||
                row.am_close !== '' ||
                row.pm_open !== '' ||
                row.pm_close !== ''
            ) {
                if (
                    row.am_open !== '' &&
                    row.am_close === '' &&
                    row.pm_open === '' &&
                    row.pm_close === ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open !== '' &&
                    row.am_close === '' &&
                    row.pm_open !== '' &&
                    row.pm_close === ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open === '' &&
                    row.am_close !== '' &&
                    row.pm_open === '' &&
                    row.pm_close !== ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open !== '' &&
                    row.am_close === '' &&
                    row.pm_open !== '' &&
                    row.pm_close !== ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open === '' &&
                    row.am_close !== '' &&
                    row.pm_open !== '' &&
                    row.pm_close !== ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open !== '' &&
                    row.am_close !== '' &&
                    row.pm_open === '' &&
                    row.pm_close !== ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open !== '' &&
                    row.am_close !== '' &&
                    row.pm_open !== '' &&
                    row.pm_close === ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open !== '' &&
                    row.am_close !== '' &&
                    row.pm_open === '' &&
                    row.pm_close !== ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open === '' &&
                    row.am_close === '' &&
                    row.pm_open === '' &&
                    row.pm_close !== ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open === '' &&
                    row.am_close !== '' &&
                    row.pm_open !== '' &&
                    row.pm_close === ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open === '' &&
                    row.am_close === '' &&
                    row.pm_open !== '' &&
                    row.pm_close === ''
                ) {
                    isValid = false;
                } else if (
                    row.am_open === '' &&
                    row.am_close !== '' &&
                    row.pm_open === '' &&
                    row.pm_close === ''
                ) {
                    isValid = false;
                }
            }
        }
        return isValid;
    };

    validateMandatory = () => {
        if (this.validateAllOpenCloseTimes()) {
            this.setState({
                error: undefined,
                isValid: true
            });
        } else {
            this.setState({
                error: 'Please ensure all required fields are completed below',
                isValid: false
            });
        }
    };

    validateNonMandatory = () => {
        if (this.validateOpenTimesByRow()) {
            this.setState({
                error: undefined,
                isValid: true
            });
        } else {
            this.setState({
                error: 'Please ensure all required fields are completed below',
                isValid: false
            });
        }
    };

    validate = () => {
        if (this.props.question.mandatory) {
            this.validateMandatory();
        }

        if (!this.props.question.mandatory) {
            this.validateNonMandatory();
        }
    };

    handleChange = async (event, day, key) => {
        const value = this.getValue();

        value[day][key] = event.target.value;

        await this.props.onChange({
            questionId: this.props.question.id,
            value: value
        });

        this.validate();
    };

    getValue = () => {
        return (
            this.props.question.value ?? {
                monday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                tuesday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                wednesday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                thursday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                friday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                saturday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                sunday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                }
            }
        );
    };

    timeAM = [
        '',
        'closed',
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00'
    ];

    time24 = [
        '',
        'closed',
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
        '00:00'
    ];

    selectOptions = items => {
        return items.map((item, index) => (
            <Select.Option key={index} value={item}>
                {item === 'closed' ? 'Closed' : item}
            </Select.Option>
        ));
    };

    selectStyle = { width: '100px' };
    inputStyle = { width: '300px' };

    days = [
        { key: 'monday', display: 'Monday' },
        { key: 'tuesday', display: 'Tuesday' },
        { key: 'wednesday', display: 'Wednesday' },
        { key: 'thursday', display: 'Thursday' },
        { key: 'friday', display: 'Friday' },
        { key: 'saturday', display: 'Saturday' },
        { key: 'sunday', display: 'Sunday' }
    ];

    amSelectItems = this.selectOptions(this.timeAM);
    allDaySelectItems = this.selectOptions(this.time24);

    render() {
        const value = this.getValue();

        return (
            <div
                className={`nhsuk-form-group ${
                    this.state.isValid === false ? 'nhsuk-form-group--error' : ''
                }`}
            >
                <label className="nhsuk-label">{this.props.question.name}</label>
                <span className="nhsuk-hint">{this.props.question.hint_text}</span>

                {this.state.isValid === false && (
                    <span className="nhsuk-error-message" role="alert">
                        {this.state.error}
                    </span>
                )}

                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell id="am-open-label">AM Open</Table.Cell>
                            <Table.Cell id="am-close-label">AM Close</Table.Cell>
                            <Table.Cell id="pm-open-label">PM Open</Table.Cell>
                            <Table.Cell id="pm-close-label">PM Close</Table.Cell>
                            <Table.Cell id="comments-label">Comments</Table.Cell>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {this.days.map((day, index) => {
                            const dayValue = value[day.key];

                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{day.display}</Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            value={dayValue.am_open ?? ''}
                                            onChange={event =>
                                                this.handleChange(event, day.key, 'am_open')
                                            }
                                            onBlur={event =>
                                                this.handleChange(event, day.key, 'am_open')
                                            }
                                            style={this.selectStyle}
                                            aria-labelledby="am-open-label"
                                        >
                                            {this.amSelectItems}
                                        </Select>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            value={dayValue.am_close ?? ''}
                                            onChange={event =>
                                                this.handleChange(event, day.key, 'am_close')
                                            }
                                            onBlur={event =>
                                                this.handleChange(event, day.key, 'am_close')
                                            }
                                            style={this.selectStyle}
                                            aria-labelledby="am-close-label"
                                        >
                                            {this.allDaySelectItems}
                                        </Select>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            value={dayValue.pm_open ?? ''}
                                            onChange={event =>
                                                this.handleChange(event, day.key, 'pm_open')
                                            }
                                            onBlur={event =>
                                                this.handleChange(event, day.key, 'pm_open')
                                            }
                                            style={this.selectStyle}
                                            aria-labelledby="pm-open-label"
                                        >
                                            {this.allDaySelectItems}
                                        </Select>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            value={dayValue.pm_close ?? ''}
                                            onChange={event =>
                                                this.handleChange(event, day.key, 'pm_close')
                                            }
                                            onBlur={event =>
                                                this.handleChange(event, day.key, 'pm_close')
                                            }
                                            style={this.selectStyle}
                                            aria-labelledby="pm-close-label"
                                        >
                                            {this.allDaySelectItems}
                                        </Select>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            className="comments"
                                            type="text"
                                            value={dayValue.comments}
                                            onChange={event =>
                                                this.handleChange(event, day.key, 'comments')
                                            }
                                            onBlur={event =>
                                                this.handleChange(event, day.key, 'comments')
                                            }
                                            style={this.inputStyle}
                                            aria-labelledby="comments-label"
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

export default OpeningTimes;
