import { Input } from 'nhsuk-react-components';
import React, { ChangeEvent, Component } from 'react';
import { InputWidth } from 'nhsuk-react-components/dist/util/types/NHSUKTypes';
import { TextBoxProps, TextBoxState } from '@form-controls';

class TextBox extends Component<TextBoxProps, TextBoxState> {
    constructor(props: TextBoxProps) {
        super(props);
        this.state = {
            error: undefined,
            isReadOnly: false,
            isValid: false
        };
    }

    componentDidMount() {
        this.setState({ isReadOnly: this.props.question.isReadOnly });
    }

    componentDidUpdate(prevProps: TextBoxProps) {
        if (prevProps.question.isReadOnly !== this.props.question.isReadOnly) {
            this.setState({ isReadOnly: this.props.question.isReadOnly });
        }
    }

    validate = () => {
        const value = this.getValue();
        const questionName = this.props.question.name;
        const max = this.props.question.max_length;
        const min = this.props.question.min_length;

        if (this.props.question.mandatory) {
            if (value.length === 0) {
                this.setState({
                    error: 'Enter the ' + this.props.question.name,
                    isValid: false
                });
            } else if (min || max) {
                this.validateMinMax(min, max, value, questionName);
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        } else if (min || max) {
            this.validateMinMax(min, max, value, questionName);
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    validateMinMax = (min: number, max: number, value: string, questionName: string) => {
        let error: string = '',
            isValid: boolean = true;

        if (min || max) {
            if (max && min && (value.length > max || value.length < min) && value.length > 0) {
                error = `${questionName} must be between ${min} and ${max} characters`;
                isValid = false;
            } else if (min && value.length < min && value.length > 0) {
                error = `${questionName} must be minimum ${min} characters in length`;
                isValid = false;
            } else if (max && value.length > max && value.length > 0) {
                error = `${questionName} exceeds the max length of ${max} characters`;
                isValid = false;
            }
            this.setState({
                error,
                isValid
            });
        }
    };

    getValue = (): string => {
        return this.props.question.value ?? '';
    };

    getWidth = (): InputWidth => {
        const validWidthValues: InputWidth[] = [2, 3, 4, 5, 10, 20, 30];
        if (this.props.question.width && validWidthValues.includes(this.props.question.width)) {
            return this.props.question.width;
        }
        return 10;
    };

    handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.onChange({
            questionId: this.props.question.id,
            value: event.target.value
        });

        this.validate();
    };

    render() {
        const value = this.getValue();

        return (
            <Input
                id={this.props.id}
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={this.state.error}
                width={this.getWidth()}
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                disabled={this.state.isReadOnly}
                required={this.props.question.mandatory}
            />
        );
    }
}

export default TextBox;
