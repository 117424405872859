import { Checkboxes, Fieldset } from 'nhsuk-react-components';

import { Component } from 'react';
import { FormQuestions } from '@form';

class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validate = () => {
        if (this.props.question.mandatory) {
            const value = this.getValue();

            if (!value.length) {
                this.setState({
                    error: 'Please make a selection',
                    isValid: false
                });
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    handleChange = async event => {
        // This can go when we upgrade to react 17
        event.persist();

        const oldValue = this.getValue();

        const newValue = event.target.checked
            ? oldValue.concat(event.target.value)
            : oldValue.filter(e => e !== event.target.value);

        await this.props.onChange({
            questionId: this.props.question.id,
            value: newValue
        });

        this.validate();

        const option = this.props.question.options.find(x => x.key === event.target.value);
        if (option.questions) {
            this.props.onConditionalReveal({
                isRevealed: event.target.checked,
                questionIds: option.questions.map(x => x.id)
            });
        }
    };

    getValue = () => {
        return this.props.question.value ?? [];
    };

    render() {
        const value = this.getValue();

        return (
            <Fieldset>
                <Fieldset.Legend>{this.props.question.name}</Fieldset.Legend>
                <Checkboxes
                    hint={this.props.question.hint_text}
                    name={this.props.question.name}
                    error={this.state.error}
                >
                    {this.props.question.options.map(option => (
                        <Checkboxes.Box
                            key={option.key}
                            value={option.key}
                            checked={value.includes(option.key)}
                            hint={option.hint_text}
                            onChange={this.handleChange}
                            required={this.props.question.mandatory}
                            conditional={
                                option.questions
                                    ? option.questions.map(question => (
                                          <FormQuestions
                                              question={this.props.questions.find(
                                                  x => x.id === question.id
                                              )}
                                              key={question.id}
                                              onQuestionChange={this.props.onChange}
                                          />
                                      ))
                                    : undefined
                            }
                        >
                            {option.value}
                        </Checkboxes.Box>
                    ))}
                </Checkboxes>
            </Fieldset>
        );
    }
}

export default Checkbox;
