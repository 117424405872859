import { Input } from 'nhsuk-react-components';
import React from 'react';
import nhsNumberValidator from 'nhs-number-validator';

class NHSNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validate = () => {
        const value = this.getValue();
        const questionName = this.props.question.name;

        let errorMessage,
            isValid = true;

        if (this.props.question.mandatory || value.length) {
            const validNHSNumberPattern =
                /^[0-9]{3}[^a-zA-Z0-9]?[0-9]{3}[^a-zA-Z0-9]?[0-9]{4}$/.test(value);
            const numbersOnly = (value.match(/\d/g) || []).join('');
            const containsLetters = /[a-zA-Z]/.test(value);

            if (validNHSNumberPattern) {
                if (!nhsNumberValidator.validate(numbersOnly)) {
                    errorMessage = `The ${questionName} is not valid`;
                    isValid = false;
                }
            } else {
                errorMessage = `The ${questionName} is not valid`;
                isValid = false;

                if (numbersOnly.length === 0) {
                    errorMessage = `The ${questionName} is required`;
                }

                if (numbersOnly.length > 0 && numbersOnly.length < 10) {
                    errorMessage = `The ${questionName} is too short`;
                }

                if (numbersOnly.length > 10) {
                    errorMessage = `The ${questionName} is too long`;
                }

                if (containsLetters) {
                    errorMessage = `The ${questionName} should not contain letters`;
                }
            }

            this.setState({
                error: errorMessage,
                isValid
            });
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    getValue = () => {
        return this.props.question.value ?? '';
    };

    handleChange = async event => {
        await this.props.onChange({
            questionId: this.props.question.id,
            value: event.target.value
        });

        this.validate();
    };

    render() {
        const value = this.getValue();
        return (
            <Input
                id={this.props.id}
                inputMode="numeric"
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                error={this.state.error}
                type="text"
                width="10"
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                required={this.props.question.mandatory}
            />
        );
    }
}

export default NHSNumber;
