import { Select as NhsUkSelect } from 'nhsuk-react-components';
import React from 'react';

class Select extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            isReadOnly: undefined,
            isValid: undefined
        };
    }

    componentDidMount() {
        this.setState({ isReadOnly: this.props.question.isReadOnly });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.question.isReadOnly !== this.props.question.isReadOnly) {
            this.setState({ isReadOnly: this.props.question.isReadOnly });
        }
    }

    validate = () => {
        if (this.props.question.mandatory) {
            if (!this.props.question.value) {
                this.setState({
                    error: 'Enter the ' + this.props.question.name,
                    isValid: false
                });
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    handleChange = async event => {
        await this.props.onChange({
            questionId: this.props.question.id,
            linked_questions: this.props.question.linked_questions
                ? this.props.question.linked_questions
                : null,
            option: this.props.question.options
                ? this.props.question.options.find(x => x.key === event.target.value)
                : null,
            value: event.target.value
        });

        this.validate();
    };

    render() {
        const value = this.props.question.value ?? '';

        return (
            <NhsUkSelect
                label={this.props.question.name}
                hint={this.props.question.hint_text}
                value={value}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                error={this.state.error}
                disabled={this.state.isReadOnly}
                required={this.props.question.mandatory}
            >
                <NhsUkSelect.Option value="">Please Select</NhsUkSelect.Option>
                {this.props.question.options.map(option => (
                    <NhsUkSelect.Option key={option.key} value={option.key}>
                        {option.value}
                    </NhsUkSelect.Option>
                ))}
            </NhsUkSelect>
        );
    }
}

export default Select;
