import { FormSummarySection, WarningSummarySection } from '@form';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Api from '@services/api';
import { Button } from 'nhsuk-react-components';
import MissingMandatoryAnswersErrorSummary from './mandatory-question-error-summary';
import ReactHtmlParser from 'react-html-parser';
import { createSummaryWarningListFromData } from '@util/warning-summary';
import { enrichFormData } from '@util/view-submission';
import { getSkippableQuestions } from '@services/form-data-section';
import { getSubmissionSectionPayload } from '@util/api-payloads';
import { identifyMissingMandatoryAnswers } from '@util/mandatory-question-validation';
import { isEmpty } from 'lodash';
import { setTitle } from '@util/browser';

const FormSummary = props => {
    const match = useRouteMatch();
    const history = useHistory();
    setTitle(`Summary - ${props.collection.name}`);

    const [showError, setShowError] = useState(false);
    const [missingAnswers, setMissingAnswers] = useState([]);
    const [enrichedData, setEnrichedData] = useState([]);

    useEffect(() => {
        setEnrichedData(enrichFormData(props.data, props.collection));
    }, [props.data, props.collection]);

    useEffect(() => {
        setMissingAnswers(identifyMissingMandatoryAnswers(enrichedData));
    }, [enrichedData]);

    useEffect(() => {
        if (missingAnswers.length > 0) {
            setShowError(true);
        }
    }, [missingAnswers]);

    const handleClick = async () => {
        const { data } = props;
        const dataSections = !isEmpty(getSkippableQuestions(data))
            ? data.filter(({ show }) => show)
            : data;

        if (missingAnswers.length <= 0) {
            const payload = {
                sections: dataSections.map(({ id, questions }) => ({
                    id,
                    questions: getSubmissionSectionPayload(questions)
                }))
            };

            const api = new Api();
            const { submissionId } = props;

            await api.putSubmission(payload, submissionId);

            const path = `/${match.params.collection}/completed`;
            history.push(path);
        }
        window.scrollTo(0, 0);
    };

    const warningList = createSummaryWarningListFromData(props.data);

    const header = props.collection.form_summary_header;
    const headerHtml = header ? Buffer.from(header, 'base64').toString('utf8') : undefined;

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>Check your answers before sending</h1>
                {showError && (
                    <MissingMandatoryAnswersErrorSummary
                        missingMandatoryAnswers={missingAnswers}
                        collection={match.params.collection}
                    />
                )}
                {ReactHtmlParser(headerHtml)}
                {!isEmpty(warningList) && <WarningSummarySection warningList={warningList} />}
                {enrichedData
                    .filter(x => x.show)
                    .map(section => (
                        <FormSummarySection
                            key={section.index}
                            onChangeClick={props.onChangeClick}
                            orgCode={props.orgCode}
                            section={section}
                            showChangeLink={true}
                        />
                    ))}
                <Button onClick={handleClick}>Submit</Button>
            </div>
        </div>
    );
};

export default FormSummary;
