import { Button } from 'nhsuk-react-components';
import React from 'react';

class CopyAnswer extends React.Component {
    handleOnCopyAnswer = e => {
        e.preventDefault();
        const { from, to } = this.props.question;
        this.props.onCopyAnswer({
            from,
            to
        });
        e.target.blur();
    };

    render() {
        return (
            <div className="nhsuk-form-group">
                <Button onClick={this.handleOnCopyAnswer}>{this.props.question.display}</Button>
            </div>
        );
    }
}

export default CopyAnswer;
