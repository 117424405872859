import { FormSummarySection, WarningSummarySection } from '@form';

import { BackLink } from '@form-controls';
import { Button } from 'nhsuk-react-components';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SubmissionIdOutput } from '@output';
import { createSummaryWarningListFromData } from '@util/warning-summary';
import { enrichFormData } from '@util/view-submission';
import { isEmpty } from 'lodash';
import { isOpen } from '@util/submission-window';
import { setTitle } from '@util/browser';
import { useHistory } from 'react-router-dom';

const ViewSubmission = props => {
    setTitle(`View Submission - ${props.collection.name}`);

    const data = enrichFormData(props.data, props.collection);
    const to = `/${props.collection.url_slug}`;
    const history = useHistory();
    const warningList = createSummaryWarningListFromData(props.data);

    const onTransferClick = () => {
        history.push(`/${props.collection.url_slug}/submission/${props.submissionId}/transfer`);
    };

    const onDeleteClick = () => {
        history.push(`/${props.collection.url_slug}/submission/${props.submissionId}/delete`);
    };

    const header = props.collection.view_submission_header;
    const headerHtml = header ? Buffer.from(header, 'base64').toString('utf8') : undefined;

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>
                    Submission <SubmissionIdOutput value={props.submissionId} />
                </h1>
                <BackLink to={to} backClick={props.backLink} />
                {ReactHtmlParser(headerHtml)}
                {!isEmpty(warningList) && <WarningSummarySection warningList={warningList} />}
                {props.collection.enable_submission_transfer && (
                    <Button
                        className="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3"
                        aria-disabled="false"
                        type="submit"
                        onClick={onTransferClick}
                    >
                        Transfer
                    </Button>
                )}
                {props.collection.enable_submission_delete && (
                    <Button
                        className="nhsuk-button nhsuk-button--secondary"
                        aria-disabled="false"
                        type="submit"
                        onClick={onDeleteClick}
                    >
                        Delete
                    </Button>
                )}
                {data
                    .filter(x => x.show)
                    .map(section => (
                        <FormSummarySection
                            key={section.index}
                            onChangeClick={props.onChangeClick}
                            orgCode={props.orgCode}
                            section={section}
                            showChangeLink={isOpen(props.collection)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default ViewSubmission;
