import { BranchSiteOutput } from '@output';
import { Select as NhsUkSelect } from 'nhsuk-react-components';
import React from 'react';
import { getBranchSites } from '@services/ods-api';

class BranchSite extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            branchSites: [],
            error: undefined,
            isValid: undefined
        };
    }

    async componentDidMount() {
        if (this.props.allowChanges) {
            const branchSites = await getBranchSites(this.props.orgCode);

            this.setState({
                branchSites: branchSites.map(x => {
                    return {
                        org_code: x.OrgId,
                        org_name: x.Name
                    };
                })
            });
        }
    }

    validate = () => {
        if (this.props.question.mandatory) {
            const value = this.getValue();

            if (!value) {
                this.setState({
                    error: 'Enter the ' + this.props.question.name,
                    isValid: false
                });
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    getValue = () => {
        return this.props.question.value;
    };

    handleChange = async event => {
        const value = this.state.branchSites.find(x => x.org_code === event.target.value);

        await this.props.onChange({
            questionId: this.props.question.id,
            value: value
        });

        this.validate();
    };

    render() {
        if (this.props.allowChanges) {
            const value = this.getValue();
            const selectedOrgCode = value ? value.org_code : '';

            return (
                <NhsUkSelect
                    label={this.props.question.name}
                    hint={this.props.question.hint_text}
                    value={selectedOrgCode}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    error={this.state.error}
                    required={this.props.question.mandatory}
                >
                    <NhsUkSelect.Option value="">Please Select</NhsUkSelect.Option>
                    {this.state.branchSites.map(branchSite => (
                        <NhsUkSelect.Option key={branchSite.org_code} value={branchSite.org_code}>
                            {branchSite.org_name} ({branchSite.org_code})
                        </NhsUkSelect.Option>
                    ))}
                </NhsUkSelect>
            );
        } else {
            return (
                <div className="nhsuk-form-group">
                    <p className="nhsuk-label">{this.props.question.name}</p>
                    <p>
                        <BranchSiteOutput question={this.props.question} />
                    </p>
                </div>
            );
        }
    }
}

export default BranchSite;
