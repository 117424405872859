import { Button, Card } from 'nhsuk-react-components';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { setTitle } from '@util/browser';

const TransferCompleted = props => {
    const match = useRouteMatch();
    const history = useHistory();
    setTitle(`Record Transferred - ${props.collection.name}`);
    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <Card feature>
                    <Card.Content>
                        <Card.Heading>Transfer Completed</Card.Heading>
                        <Card.Description>
                            This record has been transferred successfully.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <span className="nhsuk-u-margin-top-3">
                    <Button
                        className="nhsuk-button nhsuk-button--primary nhsuk-u-margin-top-3"
                        aria-disabled="false"
                        type="submit"
                        onClick={() => {
                            const path = `/${match.params.collection}`;
                            history.push(path);
                        }}
                    >
                        Back to Home
                    </Button>
                </span>
            </div>
        </div>
    );
};

export default TransferCompleted;
