import styles from './header-button.module.scss';

const HeaderButton = ({ children, href, target }) => {
    const targetProp = target ? target : '_blank';
    return href ? (
        <div className={'nhsuk-header__menu nhsuk-header__menu--only'}>
            <a
                href={href}
                target={targetProp}
                rel="noopener noreferrer"
                className={`nhsuk-header__menu-toggle ${styles.headerButton}`}
            >
                {children}
            </a>
        </div>
    ) : null;
};

export default HeaderButton;
