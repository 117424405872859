import { MapContainer, TileLayer } from 'react-leaflet';

import { Component } from 'react';
import { Draw } from 'leaflet-draw'; // eslint-disable-line
import { Fullscreen } from 'leaflet-fullscreen'; // eslint-disable-line
import L from 'leaflet';
import { getOrganisationLatLng } from '@services/org-code-geo-coder';
import { isEqual } from 'lodash';

class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            isValid: undefined
        };
    }

    validate = () => {
        if (this.props.question.mandatory) {
            const value = this.props.question.value;

            if (!value || value.features.length === 0) {
                this.setState({
                    error: `Please draw a catchment area on the map`,
                    isValid: false
                });
            } else {
                this.setState({
                    error: undefined,
                    isValid: true
                });
            }
        } else {
            this.setState({
                error: undefined,
                isValid: true
            });
        }
    };

    zoomToOrgCode = async map => {
        const latLng = await getOrganisationLatLng(this.props.orgCode);

        if (latLng) {
            map.setView(latLng, 15);
        }
    };

    handleMapCreated = map => {
        const value = this.props.question.value;
        const layer = new L.FeatureGroup();

        layer.addTo(map);

        this.setState({
            layer,
            map
        });

        if (value) {
            const geoJsonLayer = L.geoJSON(value);

            geoJsonLayer.eachLayer(x => {
                layer.addLayer(x);
            });

            const bounds = layer.getBounds();

            if (bounds.isValid()) {
                map.fitBounds(bounds);
            } else {
                this.zoomToOrgCode(map);
            }
        } else {
            this.zoomToOrgCode(map);
        }

        this.addDrawingControls(map, layer);
    };

    static getDerivedStateFromProps(props, state) {
        const { question } = props;
        const { layer, map } = state;
        if (layer && question?.value) {
            const geoJsonLayers = L.geoJSON(question.value);
            const stateLayers = layer.getLayers();

            let i = 0;
            geoJsonLayers.eachLayer(geoJsonLayer => {
                const geoJSONLayerSort = geoJsonLayer.editing?.geometry?.coordinates[0].sort();
                const filteredLayers = stateLayers.filter(stateLayer => {
                    const stateLayerSort = stateLayer.editing?.geometry?.coordinates[0].sort();
                    return isEqual(geoJSONLayerSort, stateLayerSort);
                });
                if (filteredLayers.length === 0) {
                    layer.addLayer(geoJsonLayer);
                }

                if (filteredLayers.length > 0) {
                    if (i === 0) layer.clearLayers();
                    layer.addLayer(geoJsonLayer);
                }
                i++;
            });

            const bounds = layer.getBounds();

            if (bounds.isValid()) {
                map.fitBounds(bounds);
            }
        }

        return null;
    }

    addDrawingControls = (map, layer) => {
        this.setMapTooltips();

        const options = {
            position: 'topright',
            draw: {
                polyline: false,
                polygon: true,
                circle: false,
                rectangle: false,
                marker: false,
                circlemarker: false
            },
            edit: {
                featureGroup: layer,
                remove: true
            }
        };

        const control = new L.Control.Draw(options);
        map.addControl(control);
        map.addControl(new L.Control.Fullscreen());
        map.on(L.Draw.Event.CREATED, event => this.handleDrawCreated(event, layer));
        map.on(L.Draw.Event.EDITSTOP, event => this.handleEditStop(event, layer));
        map.on(L.Draw.Event.DELETESTOP, event => this.handleDeleteStop(event, layer));
    };

    setMapTooltips = () => {
        const draw = L.drawLocal.draw;
        const edit = L.drawLocal.edit;

        draw.toolbar.buttons.polygon = 'Draw a catchment area';
        draw.handlers.polygon.tooltip.start = 'Click to start drawing a catchment area';
        draw.handlers.polygon.tooltip.cont = 'Click to continue drawing a catchment area';
        draw.handlers.polygon.tooltip.end = 'Click the first point to complete the catchment area';

        edit.toolbar.buttons.edit = 'Edit a catchment area';
        edit.toolbar.buttons.remove = 'Delete a catchment area';
        edit.handlers.edit.tooltip.text = 'Drag handles or markers to edit a catchment area';
        edit.handlers.remove.tooltip.text = 'Click on a catchment area to remove it';
    };

    handleDrawCreated = async (event, layer) => {
        layer.addLayer(event.layer);

        await this.saveChanges(layer);
    };

    saveChanges = async layer => {
        await this.props.onChange({
            questionId: this.props.question.id,
            value: layer.toGeoJSON()
        });

        this.validate();
    };

    handleCopy = async (event, layer) => {
        await this.saveChanges(layer);
    };

    handleEditStop = async (event, layer) => {
        await this.saveChanges(layer);
    };

    handleDeleteStop = async (event, layer) => {
        await this.saveChanges(layer);
    };

    render() {
        const containerStyle = {
            width: '100%',
            height: '40em'
        };

        const centre = {
            lat: 53,
            lng: -2
        };

        return (
            <div
                className={`nhsuk-form-group ${this.state.error ? 'nhsuk-form-group--error' : ''}`}
            >
                <label className="nhsuk-label">{this.props.question.name}</label>
                <span className="nhsuk-hint">{this.props.question.hint_text}</span>

                {this.state.error && (
                    <span className="nhsuk-error-message">{this.state.error}</span>
                )}

                <MapContainer
                    center={centre}
                    style={containerStyle}
                    whenCreated={this.handleMapCreated}
                    zoom={6}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </MapContainer>
            </div>
        );
    }
}

export default Map;
