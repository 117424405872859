import { Button, Fieldset, Radios } from 'nhsuk-react-components';
import React, { useState } from 'react';

import Api from '@services/api';
import { setTitle } from '@util/browser';
import { useHistory } from 'react-router-dom';

export const submissionDeleteReasons = {
    ERROR: 'created_in_error',
    DUPLICATE: 'duplicate',
    NONUSER: 'no_longer_use_service'
};

export const apiHandlers = {
    delete: async (submissionId, deleteReason, confirmDeleteHandler) => {
        const api = new Api();
        const payload = {
            reason: deleteReason
        };

        try {
            const response = await api.deleteSubmission(submissionId, payload);
            if (response) {
                confirmDeleteHandler();
            }
        } catch (error) {
            throw Error(error);
        }
    }
};

export const userActionHandlers = {
    cancel: cancelHandler => cancelHandler()
};

const ViewDeleteReasons = props => {
    setTitle(`Reason for Deletion - ${props.collection.name}`);

    const [deleteReason, setDeleteReason] = useState(null);
    const [isVisibleConfirmDelete, setIsVisibleConfirmDelete] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    const confirmDeleteHandler = () => {
        setIsVisibleConfirmDelete(true);
    };

    const cancelHandler = () => {
        history.push(`/${props.collection.url_slug}/submission/${props.submissionId}`);
    };

    const handleDelete = (event, deleteReason) => {
        event.preventDefault();
        event.target.blur();
        if (deleteReason) {
            apiHandlers.delete(props.submissionId, deleteReason, confirmDeleteHandler);
        } else {
            setError('Please select reason for deletion');
        }
    };

    if (!isVisibleConfirmDelete) {
        return (
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-full">
                    <h1>Reason for Deletion</h1>
                    <Fieldset>
                        <Fieldset.Legend>
                            Do not proceed with deletion if you may use the service in the coming
                            months. Only delete services you no longer use or are discontinued. This
                            cannot be reversed. If you are unsure then cancel this action
                        </Fieldset.Legend>
                        <Radios name="delete-reasons" id="submission-delete-reasons" error={error}>
                            <Radios.Radio
                                value={submissionDeleteReasons.DUPLICATE}
                                onClick={() => {
                                    setDeleteReason(submissionDeleteReasons.DUPLICATE);
                                    setError('');
                                }}
                            >
                                Duplicate record
                            </Radios.Radio>
                            <Radios.Radio
                                value={submissionDeleteReasons.ERROR}
                                onClick={() => {
                                    setDeleteReason(submissionDeleteReasons.ERROR);
                                    setError('');
                                }}
                            >
                                Created in Error
                            </Radios.Radio>
                            <Radios.Radio
                                value={submissionDeleteReasons.NONUSER}
                                onClick={() => {
                                    setDeleteReason(submissionDeleteReasons.NONUSER);
                                    setError('');
                                }}
                            >
                                No longer use service
                            </Radios.Radio>
                        </Radios>
                    </Fieldset>
                    <>
                        <span>
                            <Button
                                className="nhsuk-button nhsuk-button--primary"
                                aria-disabled="false"
                                type="submit"
                                onClick={event => {
                                    handleDelete(event, deleteReason);
                                }}
                            >
                                Delete
                            </Button>
                        </span>
                        <span className="nhsuk-u-padding-left-3">
                            <Button
                                className="nhsuk-button nhsuk-button--secondary"
                                aria-disabled="false"
                                type="submit"
                                onClick={() => userActionHandlers.cancel(cancelHandler)}
                            >
                                Cancel
                            </Button>
                        </span>
                    </>
                </div>
            </div>
        );
    } else {
        return <>{props.children}</>;
    }
};

export default ViewDeleteReasons;
