import { Checkboxes } from 'nhsuk-react-components';

const FilterByCheckbox = ({ submissionSummary }) => {
    return submissionSummary
        .filter(question => {
            return question.type === 'radio' || question.type === 'select';
        })
        .map(question => {
            return (
                <Checkboxes
                    question={question.id}
                    id={question.id}
                    label={question.display}
                    key={question.id}
                >
                    {question.options.map((option, index) => (
                        <Checkboxes.Box key={index} value={option.key} onChange={() => {}}>
                            {option.value}
                        </Checkboxes.Box>
                    ))}
                </Checkboxes>
            );
        });
};

export default FilterByCheckbox;
